import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "./baseUrl";
import { Redirect } from "react-router";
export default function Place(props) {
  useEffect(() => {
    props.countCartLength()

  }, []);
  if (
    localStorage.getItem("supremetech_authentication") !== "" &&
    localStorage.getItem("supremetech_authentication") !== null
  ) {
    
    // if (orderId && amount) {
      return (
        <>
          {/* <br /> */}
          <div className="container pt-5 mt-5">
            <div className="row pt-5 mt-5">
              <div className="col-md-12">
                <h2 style={{ textAlign: "center" }}> &#9989;</h2>
                <h1 style={{ textAlign: "center", color: "green" }}>
                 Order Success!
                </h1>
                <p style={{ textAlign: "center", color: "green" }}>
                  {" "}
                  Thank you for order our team will contact you shortly!
                </p>
                <p style={{ textAlign: "center"}}>Your Order Id is: #{localStorage.getItem("supreme_tech_order_id")}</p>
                <p style={{ textAlign: "center"}}>Total Amount is: ₹{localStorage.getItem("supreme_tech_amount")}</p>
              </div>
            </div>
            <div className="row">
              <div style={{ display: "flex" }} className="col-md-12">
                <div className="col-md-6 text-center" style={{ width: "50%" }}>
                  <Link to="myorders"><button className="btn" style={{ background: "rgb(220, 53, 69)", color: "white"}}>View Order</button></Link>
                </div>

                <div className="col-md-6 text-center" style={{ width: "50%" }}>
                  <Link to="/"><button className="btn" style={{ background: "rgb(37 35 35)", color: "white"}}>Continue Shopping</button></Link>
                </div>
                {/* </a> */}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <Redirect to="/fail" />;
    }
  }

