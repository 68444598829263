import axios from 'axios';
import React from 'react'
import { Link } from 'react-router-dom'
import { baseUrl } from './baseUrl';
function Footer(props) {
	const subscribe = async () => {
  const subscriptionemail = document.getElementById("subscriptionemail").value;
		const subscribeData = new FormData();
		subscribeData.append("email", subscriptionemail)
		try {
			const response = await axios.post(baseUrl+"Apicontroller/subscribe_us", subscribeData);
			console.log("Subscribe: ", response)
			if(response.data.status===200) {
				props.changePopupText("Your email is subscribe.")
			} else {
				props.changePopupText(response.data.message);
        // document.getElementById("subscriptionemail").value="";
			}
		} catch{
			console.log("Issue in subscribe api")
		}
  }
    return (
        <div>
             {/* footer start */}
      <footer className="mt-5 footer">
      <div className="container">
        {/* <div className="row ">
          <div className="col-md-6">
            <h4>
              <b>Sign up to receive our monthly newsletter</b>
            </h4>
            <div className="mt-2 mb-5 d-flex align-items-center">
              <input
                type="email"
                name
                placeholder="Enter your email address"
                id="subscriptionemail"
              />
              <input type="button" className="p-1"  style={{background: "#d7314a", color: "white", width:"30%"}}  onClick={()=> subscribe()} value="Sign up" />
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-3">
            <h5>INFORMATION</h5>
            <ul className="pl-0">
              <li>
              <Link to="/aboutus">
                About Us
              </Link>
              </li>
              <li>
                <Link to="/privacy">Privacy and Policy</Link>
              </li>
              <li>
                <Link to="/term">Terms & Conditions</Link>
              </li>
              <li>
              <Link to="/contactus">
              Contact & Support
              </Link>
              </li>
            </ul>
          </div>
         <div className="col-md-3 col-sm-6 col-xs-6 text-white">
  <div className="footer">
    <h3 className="footer-header">Bank Details</h3>
    <div style={{width: '100%' , marginTop:"-1em"}}>
      <div style={{width: '100%'}}>
        <div style={{width: '100%',fontSize:"13px",}}>SUPREME TECHNOCOM&nbsp;</div>
        <div style={{width: '100%',fontSize:"13px",}}>HDFC BANK</div>
        <div style={{width: '100%',fontSize:"13px",}}>A/C NO - 50200068683059</div>
        <div style={{width: '100%',fontSize:"13px",}}>IFSC - HDFC0003769</div>
        <div style={{width: '100%',fontSize:"13px",}}>BRANCH - CHITRAKOOT</div>
        <div class="mt-1">
    <img src="/img/phonepayimg.png"  alt="phonePay" style={{ position: "relative",width:"50%" }} />
        </div>
        <div class="mt-1" style={{width: '50%',fontSize:"13px",}}>supremetechnocom.62512349<br></br>@hdfcbank</div>
      </div>
    </div>
  </div>
</div>
<div className="col-md-3 col-sm-6 col-xs-6 text-white">
 <div className="footer">
          <div className="footer-header">
            <h5>MY ACCOUNT</h5>
            <ul className="pl-0">
            {props.authentication === "" ?
              <li>
              <Link to="/signup">
               Register Now
                </Link>
              </li>
              :
              <li>
              <Link to="/profile">
               My Profile
                </Link>
              </li>}
            </ul>
          </div>
          </div>
          </div>
   <div className="col-md-3 col-sm-6 col-xs-6 text-white">
  <div className="footer">
    <div className="footer-header">
    <h5 >Contact Us</h5>
    <p>Supreme Technocom (Rajasthan)
      <br />P.NO.262 - Basement, Yadav Marg, Gate No. 3, Sector 9 Opp., Akshardham Temple, Chitrakoot, Vaishali Nagr, Jaipur (302021)
      <br />
    </p>
    <p>Supreme Technocom (Gujrat)
      <br />Love Kush, KK Nagar Road, Patidar Chowk, Ghatlodia, Ahemdabad, (380061)
      <br />
      <br />A-18, A Block, Ground Floor, Saedar Patel Mall Nikol Gam Road, Ahemdabad (Gujrat)
    </p>
    Contact: 
    <p class="mb-0"> <a href="tel:9929098564">9929098564</a>, <a href="tel:01416769196">0141-6769196</a></p>
    <p > <a href="tel:9929098564">7627040986</a>, <a href="tel:9660582928">9660582928</a></p>
    <p>Email: salessupremejpr@gmail.com</p>
  </div>
  </div>
</div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-3">
          <div className="payment-methods d-flex">
          <p class="mb-0">© Copyright 2021 SUPREMETECH</p>
          {/*} <img src="https://www.modafurnishings.co.uk/static/version0.0.0.114/frontend/Fluid/blank/en_GB/images/payment_methods/card_visa.svg" alt="Visa" loading="lazy" />
                 <img src="https://www.modafurnishings.co.uk/static/version0.0.0.114/frontend/Fluid/blank/en_GB/images/payment_methods/card_mastercard.svg" alt="Master card" loading="lazy" />
                 <img src="https://www.modafurnishings.co.uk/static/version0.0.0.114/frontend/Fluid/blank/en_GB/images/payment_methods/card_maestro.svg" alt="Maestro" loading="lazy" />
        <img src="https://www.modafurnishings.co.uk/static/version0.0.0.114/frontend/Fluid/blank/en_GB/images/payment_methods/card_visa_electron.svg" alt="Visa Electron" loading="lazy" /> */}
        </div>
          </div>
          {/* <div className="col-md-9 d-flex text-white align-items-center justify-content-lg-end">
          Developed by{" "}
            <a href="https://www.fineoutput.com">
              <a href="https://www.fineoutput.com">
                <img
                  src="https://fineoutput.com/assets/frontend/assets/img/logo.png"
                  alt="fineoutput"
                  width="120"
                  height="50"
                />
              </a>
            </a>
          </div> */}
        </div>
      </div>
    </footer>
    {/* footer end */}
        </div>
    )
}
export default Footer
