import { CompareArrowsOutlined } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { Redirect } from "react-router";
import { baseUrl } from "./baseUrl";



function Checkout(props) {
  const [subTotal, setSubTotal] = useState(0);
  const [total, setOrderTotal] = useState(0);
  const [prevAddress, setPrevAddress] = useState([]);
  const [calculatedData, setCalD] = useState({});
  const [showPopUp, setSPU] = useState(true);
  const [popUpMessage, setPUM] = useState("");
  const [storeDetails, setStoreDetails] = useState([]);
  const [btImg, setBTImage] = useState('');
  const [PaymentMethod, setPaymentMethod] = useState(1);
  var [promoCode, setPC] = useState("");
  const [txn_id, setTxn_id] = useState("");
  const [store_id, setStore_id] = useState("")
 
  //---------------address ------------
  const [PaymentType, setPaymentType] = useState(2)



  // const [promoErrMessage, setPromoErrMessage] = useState("Apply promo code");
  const toggleShowPopUp = () => setSPU(!showPopUp);

  const radioHandler = (e) => {
    if (e === 1) {
      setPaymentType(1)
      document.getElementById("banktranfer").style.display = "none";
    } else {
      setPaymentType(2)
      document.getElementById("banktranfer").style.display = "block";
    }
  };

  const token = localStorage.getItem("user_token");
  const getCalculations = async () => {
    console.log(localStorage.getItem("supremetech_phone"), token, localStorage.getItem("supremetech_authentication"))
    const dataForCalculate = new FormData();
    dataForCalculate.append("phone", localStorage.getItem("supremetech_phone"));
    dataForCalculate.append("token_id", token);
    dataForCalculate.append(
      "authentication",
      localStorage.getItem("supremetech_authentication")
    );
    try {
      let response = await axios.post(
        baseUrl + "Apicontroller/calculate",
        dataForCalculate
      );
      console.log("calculate data:", response);
      if (response.data.status === 200) {
        setCalD(response.data);
        setSubTotal(response.data.subtotal);
        setOrderTotal(response.data.total);
        setPrevAddress(response.data.address);
        setTxn_id(response.data.txn_id);
        const newData = JSON.stringify(response.data);
        localStorage.setItem("supreme_tech_calculations", newData);
      }else{
        props.changePopupText(response.data.message);
      }
    } catch {
      console.log("Issue in calculate.");
    }
  };


  // setMyLS(myLSData)
  // ************ getting Local Storage Data *************************

  const getStoreDetails = async () => {
    const dataForStoreDetails = new FormData();
    dataForStoreDetails.append(
      "phone",
      localStorage.getItem("supremetech_phone")
    );
    dataForStoreDetails.append("token_id", token);
    dataForStoreDetails.append(
      "authentication",
      localStorage.getItem("supremetech_authentication")
    );
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/store_details",
        dataForStoreDetails
      );
      console.log("Store details:", response);
      if (response.data.status === 200) {
        setStoreDetails(response.data.data);
        console.log("this is store details: ", response.data.data)
        getViewData();
      }
    } catch {
      console.log("Issue in Store details");
    }
  };
  useEffect(() => {
    getStoreDetails();

  }, []);
  useEffect(() => {
    getCalculations();
  }, [localStorage.getItem("user_token")]);

  const payingThroughStore = (e, elem) => {
    console.log("we selected this elem: ", elem)
    setStore_id(elem.id)
  }
// ========================================================================================
  const ShowPayAtPlace = (e) => {
    setPaymentMethod(2)
    setPaymentType(1)
    document.querySelector("#payAtStore").style.display = "none";
    document.querySelector("#PayAtPlaceBtn").style.display = "block";
    document.querySelector("#ShowPayAtPlaceBtn").style.backgroundColor = "#252323";
    document.querySelector("#payAtStoreBtn").style.backgroundColor = "#d7314a";

    setStore_id('')
  };
  const ShowPayAtStore = (e) => {
    setPaymentMethod(1)
    setPaymentType(2)
    document.querySelector("#payAtStore").style.display = "block";
    document.querySelector("#payAtStoreBtn").style.backgroundColor = "#252323";
    document.querySelector("#PayAtPlaceBtn").style.display = "none";
    document.querySelector("#ShowPayAtPlaceBtn").style.backgroundColor = "#d7314a";

  };
// ========================================================================================
function onImageChange(e){
  console.log(e.target.files);
  setBTImage(e.target.files[0]);
  console.log('image'+btImg);
}
  const proceedToSuccess = async (e) => {
    e.preventDefault();
    // console.log('name---'+JSON.stringify(btImg));  
      if(PaymentType===1){
        if(btImg ===''){
          props.changePopupText("Alert! Image is required!");
          return;
        }
      }

    
      const checkoutData =new FormData(e.target) ;
      const calculatedData = JSON.parse(
        localStorage.getItem("supreme_tech_calculations")
      );
    
      // console.log('block'+e.target);
      checkoutData.append("phone", localStorage.getItem("supremetech_phone"));
      checkoutData.append("authentication",localStorage.getItem("supremetech_authentication"));
      checkoutData.append("txn_id", calculatedData.txn_id);
      checkoutData.append("token_id", token);

      // ********************* params to set ***************************
      // checkoutData.append("store_id", store_id);
      // checkoutData.append("image", btImg);
      checkoutData.append("payment_type", PaymentType);
      console.log("store_id -----"+JSON.stringify(store_id));
      console.log("checkout data -----"+JSON.stringify(checkoutData));
      try {
        const response = await axios.post(
          baseUrl + "Apicontroller/checkout",
          checkoutData
        );
        // alert(JSON.parse(response));
        console.log("placed:", response, response.data.status);
        if (response.data.status === 200) {
          localStorage.setItem("supreme_tech_amount", response.data.amount);
          localStorage.setItem("supreme_tech_order_id", response.data.order_id);
          localStorage.setItem("supreme_tech_calculations", "");
          props.history.push("/place");
        } else {
          // alert("fail");
          props.changePopupText(response.data.message);
          // props.history.push("/fail");
        }
      } catch(error) {
        console.log(error);
      }
  };


const [CartData, setVD] = useState("")
const getViewData = async () => {
  var getViewData = new FormData();
  getViewData.append("token_id", localStorage.getItem("user_token"));
  getViewData.append("phone", localStorage.getItem("supremetech_phone"));
  getViewData.append("authentication", localStorage.getItem("supremetech_authentication"));
  for (let [key, value] of getViewData) {
    console.log("View Cart ka data", key, ":", value);
  }

  try {
    const response = await axios.post(
      baseUrl+ "Apicontroller/view_cart_data",
      getViewData
    );
    console.log("Get Cart total:", response.data);
    if(response.data.status ===200){
      setVD(response.data.data);
      console.log("this is CartProdData",response.data.data)
    } else{
      setVD([])
    }
  } catch {
    console.log("Issue in getting cart data");
  }
};
  if (
    !(
      localStorage.getItem("supremetech_authentication") === "" ||
      localStorage.getItem("supremetech_authentication") === null
    )
  ) {

  // *************************************************** return *****************************************************
    return (
      <div className="mt-4 pt-2">
        {/* login start */}
        <section className="mt-5 mb-5 pt-lg-5 pb-5">
          {popUpMessage !== "" ? (
            <Toast
              className="popupboxInPromoCode"
              // style={{ top: "10rem",zIndex: "500",left: "3rem",fontSize: "1.2rem",position: "fixed" }}
              show={showPopUp}
              onClose={toggleShowPopUp}
            >
              <Toast.Header style={{ background: "#de542d", color: "white" }}>
                <span>{popUpMessage}</span>
              </Toast.Header>
            </Toast>
          ) : (
            ""
          )}
          
          <div className="container-fluid">
            <div className="row p-1">
 {/* ===================================================== order summery ========================================             */}
              <div className="col-md-5 ml-auto mt-5 mt-lg-0" style={{position:'sticky-top',top:"0"}}>
                <table className="cart_table side_cart">
                  <tbody>
                    <tr style={{ background: "#d5d9d8",textAlign:'center' }} class="not_show">
                      <th className="p-2">
                        Summary
                      </th>
                      <th>Name</th>
                      <th>Qty</th>
                      <th>Price</th>
                    </tr>
                    {CartData ? CartData.map((x) => {
                  return (
                    <tr style={{textAlign:'center'}}>
                      <td><img className="pt-3 pb-3" src={x.product_image} /></td>
                      <td style={{fontSize:11}} >{x.product_name}</td>
                      <td>{x.quantity}</td>
                      <td>₹{x.price}</td>
                    </tr>
                     );
                    })
                      : ""}
                    {/* <tr>
                      <td>Price</td>
                      <td>
                        <span>
                          <i className="fa fa-inr"></i>{" "}
                        </span>
                        {total}
                      </td>
                    </tr>
                    <tr>
                      <td>Order Total</td>
                      <td>
                        <span>
                          <i className="fa fa-inr"></i>{" "}
                        </span>
                        {subTotal}
                      </td>
                    </tr> */}
                    {/* <tr>
                    </tr> */}
                  </tbody>
                </table>
                {/* <label style={{ color: "#d21e25" }}>Freight will be added in total amount</label> */}
              </div>
 {/* ========================================= payment option ===============================              */}
              <div className="col-md-7 checkout_colm">
                <div className="hidden-menu1" style={{position:'sticky',top:'190px'}}>
                <table className="cart_table side_cart">
                  <tbody>
                    <tr>
                      <td>Total</td>
                      <td>
                        <span>
                          <i className="fa fa-inr"></i>{" "}
                        </span>
                        {total}
                      </td>
                    </tr>
                    <tr>
                      <td>Sub Total</td>
                      <td>
                        <span>
                          <i className="fa fa-inr"></i>{" "}
                        </span>
                        {subTotal}
                      </td>
                    </tr>
                    <tr>
                    </tr>
                  </tbody>
                </table>
                <label style={{ color: "#d21e25" }}>Freight Will Be Extra</label>
                
                
                  <form
                    className="d-block"
                    onSubmit={(e) => proceedToSuccess(e)}
                  >
                    <div className="d-flex">
                      <div>
                        <button
                          className="btn btn-danger"
                          type="button"
                          id="payAtStoreBtn"
                          style={{ borderRadius: '0',backgroundColor:"#252323" }}
                          onClick={(e) => ShowPayAtStore(e)}
                        >
                         Pay After Discussion
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn btn-danger ml-1"
                          type="button"
                          id="ShowPayAtPlaceBtn"
                          style={{ borderRadius: '0' }}
                          onClick={(e) => ShowPayAtPlace(e)}
                        >
                         Pay Online
                        </button>
                      </div>
                    </div>
                    <div id="payAtStore" className="hidden-menu mt-3 border p-2">
                      <h6 className="text-center">
                      Pay After Discussion
                      </h6>
                      {/* {storeDetails
                        ? storeDetails.map((elem, i) => {
                          return (
                            <div onClick={(e) => payingThroughStore(e, elem)} >
                              <a
                                className="dropdown-item fs-1 c_new2 p-2 border" style={{whiteSpace: 'unset'}}
                              href="#"
                              >
                                <p className='mb-0'>{elem.name}</p>
                                <p className='mb-0'>
                                  {elem.address +
                                    " - " +
                                    elem.pincode}
                                </p>
                                <p className='mb-0'>{elem.contact1}</p>
                                <p className='mb-0'>{elem.contact2}</p>
                              </a>
                            </div>
                          );
                        })
                        : null} */}
                    </div>
                    <div id="PayAtPlaceBtn" className="hidden-menu  mt-3  border" style={{ display: "none" }}>
                    <div id="banktranfer" className="m-2">
                      <div className="row">
                      <div className="col-md-4">
                    <img src="/img/phonepayimg.png" alt="phonePay" style={{ position: "relative" }} />
                    </div>
                      <div className="col-md-8 text-center">
                   <p > SUPREME TECHNOCOM <br/>HDFC BANK<br/>A/C NO - 
                   50200068683059<br/>IFSC - HDFC0003769 <br/>BRANCH - CHITRAKOOT
                <p style={{ fontWeight: "bold", fontSize: "1rem"}}>supremetechnocom.62512349@hdfcbank</p>
                </p>
                    </div>
                    </div>
                          <label className="btn p-1" for="btd_img">
                            Upload Transfer Details <sub>*</sub>
                          </label>
                          <input
                            className="form-control"
                            id="btd_img"
                          onChange={(e) => setBTImage(e)}
                          placeholder="Upload"
                          name="image"
                          type="file"
                          />                       
                      </div>
                    </div>
                    <div className="mt-5">
                      <input
                        type="submit"
                        className="btn  p-2"
                        style={{ borderRadius: '0',backgroundColor:'#2b2b2b',color:'white' }}
                        name="submit"
                        value="Place Order"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <Redirect to="/register" />;
  }
}

export default Checkout;
