import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Productdetails.css";
// import { useParams} from 'react-router-dom'
import ProductSlider from "./ProductSlider";
import { baseUrl } from "./baseUrl";
import ProductDetailsSlider from "./ProductDetailSlider";
function Productdetails(props) {
  const product_id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  const [Pdetail, setPdetail] = useState([]);
  const [relatedproduct, setRelatedproduct] = useState([]);
  const [authentication, setAuthentication] = useState("");
  const [num, setNum] = useState(1);
  const [max, setMax] = useState('');
  const inputNum = (event) => {
    const re = /^[0-9\b]+$/;
    if(event.target.value <=0){
      setNum(1)
    }else if(event.target.value === '' || re.test(event.target.value)===false){
 
    }else{
      // alert(max)
    if(parseInt(event.target.value)<parseInt(max)){
      setNum(event.target.value);
    }else{
      setNum(max)
    }
  }

  };
  const incNum = () => {
    if (num < max) {
      setNum(num + 1);
    }else{
      props.changePopupText('Product max quantity limit reached');
    }
  };

  const decNum = () => {
    if (num >= 2) {
      setNum(num - 1);
    }
  };
  const checkAndSetCredentials = () => {
    // Getting phone, authentication and token from local storage
    const user_phone = localStorage.getItem("supremetech_phone");
    const user_authentication = localStorage.getItem(
      "supremetech_authentication"
    );

    // If login/Registered
    if (user_phone && user_authentication) {
      setAuthentication(user_authentication);
    }
  }
  const getrelatedproduct = async () => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/related_products/" + product_id
      );
      console.log("related products:", response);
      if (response.data.status === 200) {
        setRelatedproduct(response.data.data);
      }
    } catch {
      console.log("Issue in related products.");
    }
  };
  const productdetails = async () => {
    try {
      let response = await axios.get(
        baseUrl+"Apicontroller/get_productdetails/"+product_id
      );
      console.log("productdetails:", response.data);
      if (response.data.status === 200) {
        setPdetail(response.data.data);
        setMax(response.data.data[0].max);
      }
    } catch {
      console.log("Issue in productdetails.");
    }
  };


  useEffect(() => {
    checkAndSetCredentials()
    productdetails();
    getrelatedproduct();
  }, [product_id]);


  return (
    <div>
      {/* header end */}
      {/* filter */}
      <div className="mt-5 mt-md-0 details_page">
        <div className="container ">
          <div className="row">
            {Pdetail
              ? Pdetail.map((elem) => {
                  return (
                    <>
                    <div className="col-md-8 p-0 mgr mb-5">
                        <ProductDetailsSlider
                          productImages={[
                            elem.productimage1,
                            elem.productimage2,
                          ]}
                          productVideos={[
                            elem.productvideo1,
                            elem.productvideo2
                          ]}
                        />
                      </div>
                      
                    <div className="col-md-4 mt-4 pt-md-5 pt-lg-5 pt-sm-5 mt-sm-5 mr-auto">
                      <h4 className="mb-0 mt-3 mt-lg-0">{elem.productname}</h4>
                      <span>SKU: {elem.modelno}</span>
                      {/* <p className="write_rat">
                        <span>Write Your Review</span>
                      </p> */}
                      
                      {/* <p className="old_price mb-0">
                        <span>M.R.P: </span>
                        <del>{elem.mrp}</del>
                      </p> */}
                       {authentication !== "" ?
                      <p className="special_price mb-0 mt-3">
                        <span>Price: </span>
                        <span className="price_label">
                        ₹{elem.price}
                        </span>
                        <span className="taxnote"> (GST Included)</span>
                      </p>
                      :null}
                      <p>
                      <span>Availability:{" "}</span>
                        <span className='stok' style={{fontWeight:"600",color:elem.stock === 1?'green':'red'}}>{elem.stock===1?"In Stock":"Out of Stock"}</span>
                      </p>
                      {/* <p className="mb-0">
                        by 
                        <span className="stok"> Supremetech</span>
                      </p> */}
                      <span style={{ fontSize: "x-small" }}></span>
                      <p></p>
                      <div className="qty-block">
                        <label htmlFor="qty">Qty:</label>
                        <div className="cart-plus-minus">
                          <form
                            id="myform"
                            method="POST"
                            className="quantity"
                            action="#"
                          >
                            <input
                              type="button"
                              defaultValue="-"
                              className="qtyminus minus p_qty text-white"
                              onClick={()=>decNum()}
                              field="quantity"
                            />
                            <input
                              type="text"
                              name="quantity"
                              value={num}
                              onChange={inputNum}
                              className="qty q_input"
                            />
                            <input
                              type="button"
                              defaultValue="+"
                              className="qtyplus plus p_qty text-white"
                              onClick={()=>incNum()}
                              field="quantity"
                            />
                          </form>
                        </div>
                       
                      </div>
                      <input type="hidden" id="minorder" defaultValue={150} />
                      {/* <div className="min-qty"><span style={{fontWeight:"600"}}>Max Quantity :</span> {elem.max}</div> */}
                      <div className="col mt-4   " >
                        <div className="col-md-6">
                          <button
                          style={{background:"#2b2b2b",
                          color:'white',
                          width:"17em",
                          marginLeft:"-30px",
                        }}
                          className={"btn  mr-5  add_btn"}
                            onClick={() => props.addingToCart(elem.id, num)}
                          >
                            ADD TO CART
                          </button>
                        </div>
                        {
                        localStorage.getItem("supremetech_authentication")
                        ?(
                        <div className="col-md-6 pt-2">
                       
                            <button className="pro_btn"
                            style={{background:"#2b2b2b",
                            width:"17em",
                            marginLeft:"-30px",
                          }}
                          
                            onClick={() => props.addingToWishlist(elem.id, num)}
                            >
                              ADD TO WISHLIST
                            </button>
                        </div>):null}
                      </div>
                      {/* <p className="text_uper mt-4 mb-0">Delivery </p>
                      <p className>
                        <b>Usually Delivers in 12 to 48 hours</b>{" "}
                      </p>
                      <p className="text_uper mt-4">Shipping</p>
                      <p className>
                        Free shipping on all orders
                        <small>(Subject to order value)</small>
                      </p>
                      <p className="text_uper mt-4">Easy Payment Options</p>
                      <p className></p>
                      <ul className="pl-3">
                        <li>Net banking &amp; Credit/ Debit/ UPI</li>
                        <li>Cash on Delivery</li>
                        <li>Demand Draft / Cheque / Money order</li>
                      </ul>
                      <p /> */}
                      <div className="accordion mt-5" id="accordionExample">
                      <div
                          className="card cardtext"
                          style={{
                            border: "0",
                            borderRadius: "0",
                            outline: "0",
                            borderTop: "1px solid rgba(0,0,0,.125)",
                            borderBottom: "1px solid rgba(0,0,0,.125)",
                            // width:"380px"
                          }}
                        >
                          <div
                            className="card-header"
                            id="headingOne"
                            style={{
                              border: "0",
                              borderRadius: "0",
                              outline: "0",
                              background: "white",
                            }}
                          >
                            <h2
                              className="mb-0"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                                marginLeft: "-2rem"
                              }}
                            >
                              <button
                                className="btn justify-content-between d-flex w-100"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                style={{
                                  border: "0",
                                  borderRadius: "0",
                                  outline: "0",
                                  boxShadow: "none"

                                }}
                              >
                                <span>Product Description</span>
                                <i class="fa fa-chevron-down" style={{ marginRight: '-28px' }}></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseOne"
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div
                              className="card-body"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",

                              }}
                            >
                              <div dangerouslySetInnerHTML={{ __html: elem.productdescription }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </>
                  );
                })
                
              : null}
          </div>
        </div>
      </div>
      {/* product details end */}
      {/* related products start */}
      {relatedproduct?.length > 0 ?  
      <section className="mt-5 pt-5">
        <div className="container overflow-hidden">
          <div className="row mb-4">
            <div className="col-md-12 text-center">
              <h1 style={{marginBottom:"0px"}}>RELATED PRODUCTS</h1>
              <img src="../img/bottom.png" style={{width:"13rem",marginTop:"-10px",marginBottom:"10px"}}></img> 
              {/* <p>The furniture our customers love:</p> */}
            </div>
          </div>
          {relatedproduct ? (
            <ProductSlider
              products={relatedproduct}
              isBrand={false}
              isSlider={true}
              addingToCartFunction={props.addingToCart}
              mostpopularbrands={[]}
            />
          ) : (
            ""
          )}
        </div>
      </section>
      :null}
      {/* related products  end */}
      {/* footer start */}

      {/* footer end */}
    </div>
  );
}

export default Productdetails;
