import React, { useState, useEffect } from "react";
import axios from "axios";

import { baseUrl } from "./baseUrl";
import ProductCard from "./ProductCard";

function Category(props) {
  const [allproduct, setallProduct] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [modalDis,setModalDis] = useState(false);
  const category = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  // console.log("this is category from path: ", category)

let anchorHandler = (e)=>{
  e.preventDefault()
  console.log('this is anchor handler!');
}

  async function fetchAllProductData() {
    const formData = new FormData();
    formData.append("category_id", category);
    console.log('this is form data',formData)
  //   for (var pair of formData.entries()) {
  //     console.log("this is my for loop for formData: ",pair[0]+ ', ' + pair[1]); 
  // }
    try {
      const data = await axios.post(
        baseUrl + "Apicontroller/get_category_products",
        formData
      );
      console.log("category all pd:", data);
      setallProduct(data.data.data);
      setCategoryName(data.data.category);
    } catch {
      console.log("Issue in get product detail.");
    }
  }
  useEffect(() => {
    fetchAllProductData();
  }, [category]);
  const [FilterName, setFN] = useState({
    brand: [],
    resoulation: [],
    irdistance: [],
    cameratype: [],
    bodymaterial: [],
    videochannel: [],
    poeports: [],
    poetype: [],
    sataports: [],
    lengths: [],
    screensize: [],
    ledtype: [],
    size: [],
    lens: [],
  });

  const [emptyMessage, setEmptyMessage] = useState("");
  const [resoulation, setresoulation] = useState("");
  const [irdistance, setIrdistance] = useState("");
  const [cameratype, setCameratype] = useState("");
  const [bodymaterial, setBodymaterial] = useState("");
  const [videochannel, setVideochannel] = useState("");
  const [poeports, setPoeports] = useState("");
  const [poetype, setPoetype] = useState("");
  const [sataports, setSataports] = useState("");
  const [length, setLength] = useState("");
  const [screensize, setScreensize] = useState("");
  const [ledtype, setLedtype] = useState("");
  const [size, setSize] = useState("");
  const [lens, setLens] = useState("");
  const [brand, setBrand] = useState("");
  useEffect(() => {
    async function fetchFilterNameData() {
      try {
        const response = await axios(baseUrl + "Apicontroller/view_filter/");

        console.log("Filter name:", response.data.data, response.data.status);
        if (response.data.status === 200) {
          setFN(response.data.data[0]);
        }
        // setAP(response.data.data);
      } catch {
        console.log("Issue in filter name");
      }
    }
    fetchFilterNameData();
  }, [category]);

  const setFilters = (e) => {
    // alert(e.target.checked);
    console.log("e k andar", e.target.attributes.filterid, e.target.id);
    const filterId = document
      .getElementById(e.target.id)
      .getAttribute("filterId");
      // console.log("this is filter id by getElem: ", filterId)
    const filterName = document
      .getElementById(e.target.id)
      .getAttribute("filterName");
      // console.log("this is filter name by getElem: ", filterName)
    // const filterApiInputTemp = {...filterApiInput};
    var filterApiInputTemp = {};
    // alert("hiiiii")
    if (filterName === "brand") {
      // alert("hiiiii")
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (brand === "") {
          setBrand(filterId);
        } else {
          let newdata = brand + "," + filterId;
          setBrand(newdata);
        }
      } else {
        if (brand !== "") {
          let prevArray = brand.split(",");
          // console.log("this is prevArray: ",prevArray)
          let index = brand.split(",").indexOf(filterId);
          // console.log("this is index: ",index)
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            brand.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setBrand(newdata);
        }
      }
    }
    if (filterName === "resoulation") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (resoulation === "") {
          setresoulation(filterId);
        } else {
          let newdata = resoulation + "," + filterId;
          setresoulation(newdata);
        }
      } else {
        if (resoulation !== "") {
          let prevArray = resoulation.split(",");
          let index = resoulation.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            resoulation.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setresoulation(newdata);
        }
      }
    }
    if (filterName === "irdistance") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (irdistance === "") {
          setIrdistance(filterId);
        } else {
          let newdata = irdistance + "," + filterId;
          setIrdistance(newdata);
        }
      } else {
        if (irdistance !== "") {
          let prevArray = irdistance.split(",");
          let index = irdistance.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            irdistance.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setIrdistance(newdata);
        }
      }
    }
    if (filterName === "cameratype") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (cameratype === "") {
          setCameratype(filterId);
        } else {
          let newdata = cameratype + "," + filterId;
          setCameratype(newdata);
        }
      } else {
        if (cameratype !== "") {
          let prevArray = cameratype.split(",");
          let index = cameratype.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            cameratype.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setCameratype(newdata);
        }
      }
    }
    if (filterName === "bodymaterial") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (bodymaterial === "") {
          setBodymaterial(filterId);
        } else {
          let newdata = bodymaterial + "," + filterId;
          setBodymaterial(newdata);
        }
      } else {
        if (bodymaterial !== "") {
          let prevArray = bodymaterial.split(",");
          let index = bodymaterial.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            bodymaterial.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setBodymaterial(newdata);
        }
      }
    }
    if (filterName === "videochannel") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (videochannel === "") {
          setVideochannel(filterId);
        } else {
          let newdata = videochannel + "," + filterId;
          setVideochannel(newdata);
        }
      } else {
        if (videochannel !== "") {
          let prevArray = videochannel.split(",");
          let index = videochannel.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            videochannel.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setVideochannel(newdata);
        }
      }
    }
    if (filterName === "poeports") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (poeports === "") {
          setPoeports(filterId);
        } else {
          let newdata = poeports + "," + filterId;
          setPoeports(newdata);
        }
      } else {
        if (poeports !== "") {
          let prevArray = poeports.split(",");
          let index = poeports.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            poeports.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setPoeports(newdata);
        }
      }
    }
    if (filterName === "poetype") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (poetype === "") {
          setPoetype(filterId);
        } else {
          let newdata = poetype + "," + filterId;
          setPoetype(newdata);
        }
      } else {
        if (poetype !== "") {
          let prevArray = poetype.split(",");
          let index = poetype.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            poetype.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setPoetype(newdata);
        }
      }
    }
    if (filterName === "sataports") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (sataports === "") {
          setSataports(filterId);
        } else {
          let newdata = sataports + "," + filterId;
          setSataports(newdata);
        }
      } else {
        if (sataports !== "") {
          let prevArray = sataports.split(",");
          let index = sataports.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            sataports.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setSataports(newdata);
        }
      }
    }
    if (filterName === "length") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (length === "") {
          setLength(filterId);
        } else {
          let newdata = length + "," + filterId;
          setLength(newdata);
        }
      } else {
        if (length !== "") {
          let prevArray = length.split(",");
          let index = length.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            length.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setLength(newdata);
        }
      }
    }
    if (filterName === "screensize") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (screensize === "") {
          setScreensize(filterId);
        } else {
          let newdata = screensize + "," + filterId;
          setScreensize(newdata);
        }
      } else {
        if (screensize !== "") {
          let prevArray = screensize.split(",");
          let index = screensize.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            screensize.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setScreensize(newdata);
        }
      }
    }
    if (filterName === "ledtype") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (ledtype === "") {
          setLedtype(filterId);
        } else {
          let newdata = ledtype + "," + filterId;
          setLedtype(newdata);
        }
      } else {
        if (ledtype !== "") {
          let prevArray = ledtype.split(",");
          let index = ledtype.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            ledtype.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setLedtype(newdata);
        }
      }
    }
    if (filterName === "size") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (size === "") {
          setSize(filterId);
        } else {
          let newdata = size + "," + filterId;
          setSize(newdata);
        }
      } else {
        if (size !== "") {
          let prevArray = size.split(",");
          let index = size.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            size.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setSize(newdata);
        }
      }
    }
    if (filterName === "lens") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (lens === "") {
          setLens(filterId);
        } else {
          let newdata = lens + "," + filterId;
          setLens(newdata);
        }
      } else {
        if (lens !== "") {
          let prevArray = lens.split(",");
          let index = lens.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            lens.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setLens(newdata);
        }
      }
    }
  };
  const applyingFilterAPI = async () => {
    console.log("this brand is from applying filter: ", brand);
    console.log('applyingFilter function fire');
    if (
      brand !== "" ||
      resoulation !== "" ||
      irdistance !== "" ||
      cameratype !== "" ||
      bodymaterial !== "" ||
      videochannel !== "" ||
      poeports !== "" ||
      poetype !== "" ||
      sataports !== "" ||
      length !== "" ||
      screensize !== "" ||
      ledtype !== "" ||
      size !== "" ||
      lens !== ""
      ) {
        // alert("hi")
        
        console.log("Applying filter api");

      const appliedFilterData = new FormData();
      appliedFilterData.append("brand_id", brand);
      appliedFilterData.append("resolution_id", resoulation);
      appliedFilterData.append("irdistance_id", irdistance);
      appliedFilterData.append("cameratype_id", cameratype);
      appliedFilterData.append("bodymaterial_id", bodymaterial);
      appliedFilterData.append("videochannel_id", videochannel);
      appliedFilterData.append("poeports_id", poeports);
      appliedFilterData.append("poetype_id", poetype);
      appliedFilterData.append("sataports_id", sataports);
      appliedFilterData.append("length_id", length);
      appliedFilterData.append("screensize_id", screensize);
      appliedFilterData.append("ledtype_id", ledtype);
      appliedFilterData.append("size_id", size);
      appliedFilterData.append("lens_id", lens);
      for (let pair of appliedFilterData.entries()) {
        console.log(pair[0], ":", pair[1]);
      }
      try {
        const response = await axios.post(baseUrl + "Apicontroller/filter", appliedFilterData);
        console.log("filtered product data api res: ", response);
        if (response.data.status === 201) {
          setallProduct([]);
          setEmptyMessage("No product available.");
        } else {
          console.log("filtered product data else", response.data.data);
          setallProduct(response.data.data);
          setEmptyMessage("");
        }
      } catch {
        console.log("Issue in getting filterd data");
      }
    } else {
      fetchAllProductData();
    }
  };
  return (
    <div>
      {/* filter */}
      <section className="filter pt-5 mt-5 accordion" id="accordionExample">
        <div className="container" style={{ maxWidth: "100vw" }}>
          <div class="row heading-div">
            <div class="col-md-12 text-center">
              <h1 className="heading">{categoryName}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-5 d-block d-lg-none d-md-none">
              <button
                type="button"
                className="btn btn-filter"
                data-toggle="modal"
                data-target="#myModal"
              >
                <img
                  style={{ width: "30px" }}
                  className="mr-3"
                  src="../img/filter.png"
                />{" "}
                FILTER
              </button>
              {/* <!-- filter model start --> */}
              <div
                className="modal"
                id="myModal"
                aria-modal="true"
                role="dialog"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                      <h4 className="modal-title">FILTER</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        ×
                      </button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body p-0">
                      <ul className="d-block">
                        <li className="ml-3">
                          <img
                            style={{ width: "30px" }}
                            src="../img/filter.png"
                          />
                        </li>
                        <li id="headingOne">
                          <a
                            className=""
                            data-toggle="collapse"
                            type="button"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Brand
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          
                          
                            </a>
                        </li>
                        <div
                          className="container collapse p-0"
                          id="collapseOne"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {/* console.log("hello:",FilterName.brand) */}
                            {FilterName.brand
                              ? FilterName.brand.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"brand" + filterId}
                                        filterid={filterId}
                                        filtername="brand"
                                        onClick={(e) => setFilters(e)}
                                      />
                                      <span className="sub_sp">{elem.name}</span>
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        </div>

                        <li id="headingTwo">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Resolution
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapseTwo"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.resoulation
                              ? FilterName.resoulation.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"resoulation" + filterId}
                                        filterid={filterId}
                                        filtername="resoulation"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">{elem.name}</span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingThree">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Lens
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapseThree"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.lens
                              ? FilterName.lens.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"lens" + filterId}
                                        filterid={filterId}
                                        filtername="lens"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">{elem.name}</span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingfour">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapsefour"
                          >
                            IR Distance
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapsefour"
                          aria-labelledby="headingfour"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.irdistance
                              ? FilterName.irdistance.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"irdistance" + filterId}
                                        filterid={filterId}
                                        filtername="irdistance"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">{elem.name}</span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingfive">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapsefive"
                            aria-expanded="false"
                            aria-controls="collapsefive"
                          >
                            Camera Type
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapsefive"
                          aria-labelledby="headingfive"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.cameratype
                              ? FilterName.cameratype.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"cameratype" + filterId}
                                        filterid={filterId}
                                        filtername="cameratype"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">
                                      {elem.name}
                                      </span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingsix">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapsesix"
                            aria-expanded="false"
                            aria-controls="collapsesix"
                          >
                            Body Meterial
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapsesix"
                          aria-labelledby="headingsix"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.bodymaterial
                              ? FilterName.bodymaterial.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"bodymaterial" + filterId}
                                        filterid={filterId}
                                        filtername="bodymaterial"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">
                                        {elem.name}
                                      </span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingseven">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseseven"
                            aria-expanded="false"
                            aria-controls="collapseseven"
                          >
                            VIDEO CHANNEL
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapseseven"
                          aria-labelledby="headingseven"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.videochannel
                              ? FilterName.videochannel.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"videochannel" + filterId}
                                        filterid={filterId}
                                        filtername="videochannel"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">
                                        {elem.name}
                                      </span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingeight">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseeight"
                            aria-expanded="false"
                            aria-controls="collapseeight"
                          >
                            POE PORTS
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapseeight"
                          aria-labelledby="headingeight"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.poeports
                              ? FilterName.poeports.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"poeports" + filterId}
                                        filterid={filterId}
                                        filtername="poeports"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">
                                        {elem.name}
                                      </span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingnine">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapsenine"
                            aria-expanded="false"
                            aria-controls="collapsenine"
                          >
                            SATA PORTS
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapsenine"
                          aria-labelledby="headingnine"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.sataports
                              ? FilterName.sataports.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"sataports" + filterId}
                                        filterid={filterId}
                                        filtername="sataports"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">
                                        {elem.name}
                                      </span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingten">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseten"
                            aria-expanded="false"
                            aria-controls="collapseten"
                          >
                            LENGTH
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapseten"
                          aria-labelledby="headingten"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.lengths
                              ? FilterName.lengths.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"length" + filterId}
                                        filterid={filterId}
                                        filtername="length"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">
                                        {elem.name}
                                      </span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingeleven">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseeleven"
                            aria-expanded="false"
                            aria-controls="collapseeleven"
                          >
                            SCREEN SIZE
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapseeleven"
                          aria-labelledby="headingeleven"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.screensize
                              ? FilterName.screensize.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"screensize" + filterId}
                                        filterid={filterId}
                                        filtername="screensize"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">
                                        {elem.name}
                                      </span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingtwelve">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapsetwelve"
                            aria-expanded="false"
                            aria-controls="collapsetwelve"
                          >
                            LED TYPE
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <div
                          className="container collapse p-0"
                          id="collapsetwelve"
                          aria-labelledby="headingtwelve"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.ledtype
                              ? FilterName.ledtype.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"ledtype" + filterId}
                                        filterid={filterId}
                                        filtername="ledtype"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">
                                        {elem.name}
                                      </span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>

                        <li id="headingthirteen">
                          <a
                            className="collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapsethirteen"
                            aria-expanded="false"
                            aria-controls="collapsethirteen"
                          >
                            SIZE
                            <i
                              className="fa fa-angle-down"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>

                        <div
                          className="container collapse p-0"
                          id="collapsethirteen"
                          aria-labelledby="headingthirteen"
                          data-parent="#accordionExample"
                        >
                          <div className="sub_cat">
                            {FilterName.size
                              ? FilterName.size.map((elem, i) => {
                                  let filterId = i + 1;
                                  return (
                                    <div className="d-flex py-3 px-3">
                                      <input
                                        type="checkbox"
                                        id={"size" + filterId}
                                        filterid={filterId}
                                        filtername="size"
                                        onClick={(e) => setFilters(e)}
                                      />{" "}
                                      <span className="sub_sp">
                                        {elem.name}
                                      </span>{" "}
                                      {/* <span className="sub_sp1">(17)</span> */}
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 d-none d-lg-block d-md-block">
              {/* <!-- filter model end --> */}
              <ul>
                <li className="ml-1">
                  <img style={{ width: "30px" }} src="../img/filter.png" />
                </li>
                <li id="headingOne">
                  <a
                    className="thisIsTheAnchor"
                    onClick={()=>setModalDis(!modalDis)}
                    data-toggle="collapse"
                    type="button"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Brand
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>

                <li id="headingTwo">
                  <a
                    className="collapsed"
                    onClick={()=>setModalDis(!modalDis)}
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    resolution
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>

                <li id="headingThree">
                  <a
                    className="collapsed"
                    onClick={()=>setModalDis(!modalDis)}
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    IR DISTANCE
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>
                <li id="headingfour">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    CAMERA TYPE
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>
                <li id="headingfive">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsefive"
                    aria-expanded="false"
                    aria-controls="collapsefive"
                  >
                    BODY MATERIALS
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>
                <li id="headingsix">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsesix"
                    aria-expanded="false"
                    aria-controls="collapsesix"
                  >
                    Video Channel
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>
                <li id="headingseven">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseseven"
                    aria-expanded="false"
                    aria-controls="collapseseven"
                  >
                    POE PORTS
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>

                <li id="headingeight">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseeight"
                    aria-expanded="false"
                    aria-controls="collapseeight"
                  >
                    POE TYPES
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>

                <li id="headingnine">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsenine"
                    aria-expanded="false"
                    aria-controls="collapsenine"
                  >
                    SATA PORTS
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>

                <li id="headingten">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseten"
                    aria-expanded="false"
                    aria-controls="collapseten"
                  >
                    Length
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>

                <li id="headingeleven">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseeleven"
                    aria-expanded="false"
                    aria-controls="collapseeleven"
                  >
                    SCREEN SIZE
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>

                <li id="headingtwelve">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsetwelve"
                    aria-expanded="false"
                    aria-controls="collapsetwelve"
                  >
                    LED TYPE
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>

                <li id="headingthirteen">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsethirteen"
                    aria-expanded="false"
                    aria-controls="collapsethirteen"
                  >
                    SIZE
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>
                <li id="headingfourteen">
                  <a
                   onClick={()=>setModalDis(!modalDis)}
                    className="collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseeight"
                    aria-expanded="false"
                    aria-controls="collapseeight"
                  >
                    LENS
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <button
                    className={"collapsed btn btn-primary ml-auto mr-1 ml-md-auto mr-md-1 ml-lg-auto mr-lg-1"}
                    onClick={() => {
                      setModalDis(!modalDis);
      
                      return  applyingFilterAPI()
                    }}
                    style={{
                      background: "#d7314a",
                      color: "white",
                    }}
                    type="button"
                    data-toggle="collapse"
                    // data-target="#collapseall"
                    aria-expanded="false"
                    // aria-controls="collapseall"
                    
                    // data-toggle="collapse"
                    // type="button"
                    // data-target="#collapseOne"
                    // aria-expanded="false"
                    // aria-controls="collapseOne"
                  >
                    Apply
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapseOne"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.brand
              ? FilterName.brand.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"brand" + filterId}
                        filterid={filterId}
                        filtername="brand"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          
          id="collapseTwo"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
        >
          <div className={"sub_cat"}>
            {FilterName.resoulation
              ? FilterName.resoulation.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"resoulation" + filterId}
                        filterid={filterId}
                        filtername="resoulation"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapseThree"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.irdistance
              ? FilterName.irdistance.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"irdistance" + filterId}
                        filterid={filterId}
                        filtername="irdistance"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapsefour"
          aria-labelledby="headingfour"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.cameratype
              ? FilterName.cameratype.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"cameratype" + filterId}
                        filterid={filterId}
                        filtername="cameratype"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapsefive"
          aria-labelledby="headingfive"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.bodymaterial
              ? FilterName.bodymaterial.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"bodymaterial" + filterId}
                        filterid={filterId}
                        filtername="bodymaterial"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapsesix"
          aria-labelledby="headingsix"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.videochannel
              ? FilterName.videochannel.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"videochannel" + filterId}
                        filterid={filterId}
                        filtername="videochannel"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapseseven"
          aria-labelledby="headingseven"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.poeports
              ? FilterName.poeports.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"poeports" + filterId}
                        filterid={filterId}
                        filtername="poeports"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapseeight"
          aria-labelledby="headingeight"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.poetype
              ? FilterName.poetype.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"poetype" + filterId}
                        filterid={filterId}
                        filtername="poetype"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapsenine"
          aria-labelledby="headingnine"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.sataports
              ? FilterName.sataports.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"sataports" + filterId}
                        filterid={filterId}
                        filtername="sataports"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapseten"
          aria-labelledby="headingten"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.lengths
              ? FilterName.lengths.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"length" + filterId}
                        filterid={filterId}
                        filtername="length"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapseeleven"
          aria-labelledby="headingeleven"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.screensize
              ? FilterName.screensize.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"screensize" + filterId}
                        filterid={filterId}
                        filtername="screensize"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapsetwelve"
          aria-labelledby="headingtwelve"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.ledtype
              ? FilterName.ledtype.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"ledtype" + filterId}
                        filterid={filterId}
                        filtername="ledtype"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapsethirteen"
          aria-labelledby="headingthirteen"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.lens
              ? FilterName.lens.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"lens" + filterId}
                        filterid={filterId}
                        filtername="lens"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <div
          style={{ maxWidth: "100vw" }}
          className={modalDis?"container collapse inActvMOd":"container collapse"}
          id="collapsethirteen"
          aria-labelledby="headingthirteen"
          data-parent="#accordionExample"
        >
          <div className="sub_cat">
            {FilterName.ledtype
              ? FilterName.ledtype.map((elem, i) => {
                  let filterId = i + 1;
                  return (
                    <div className="d-flex py-3 px-3">
                      <input
                        type="checkbox"
                        id={"ledtype" + filterId}
                        filterid={filterId}
                        filtername="ledtype"
                        onClick={(e) => setFilters(e)}
                      />{" "}
                      <span className="sub_sp">{elem.name}</span>{" "}
                      {/* <span className="sub_sp1">(17)</span> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </section>
      <section className="filter_img mt-5">
        <div className="container">
          <div className="row">
            {allproduct
              ? allproduct.map((elem) => {
                console.log("this is catProd mrp map", elem.mrp , elem.MRP)
                  return (
                    <ProductCard
                      product_id={elem.product_id}
                      productimage={elem.image?elem.image:elem.product_image}
                      productname={elem.product_name}
                      mrp={elem.mrp?elem.mrp: elem.MRP}
                      price={elem.price}
                      addingToCartFunction={props.addingToCart}
                      isSlider="false"
                    />
                  );
                })
              : null}
          </div>
          <div className="row">
            <div className="col-12 col-md-12 text-center">{emptyMessage}</div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Category;