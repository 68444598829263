import React from 'react'
function Privacy() {
    return (
        <div>
            <section className="ptb-50 ptb-xs-30 gray-bg">
                <div className="container">
                    <div className="row testimonial">
                        <div className="col-md-12 mt-5">
                            <div className="mt-5">
                                <h5>Privacy Policy</h5>
                                <p className="text-justify">Updated at 2023-05-30</p>
                                <p>
                                    This Privacy Policy describes how we collect, use, store, and disclose information when you use our mobile application as a distributor, trader, supplier, and exporter of Digital & Analog Electronic Equipment ("we," "us," or "our"). It applies to the mobile application available on the Google Play Store ("SUPREME TECHNOCOM").
                                </p>
                                <p>
                                    By installing, accessing, or using our App, you agree to the collection and use of your information in accordance with this Privacy Policy. If you do not agree with the practices described in this Privacy Policy, please do not install, access, or use the App.
                                </p>
                                <h3>Personal and Sensitive User Data</h3>
                                <h5>1. Personal Information</h5>
                                <p>
                                    When you install and use the App, we may collect personal information such as your name, email address, phone number, and company details. We only collect this information if you voluntarily provide it to us.
                                </p>
                                <h5>2. Device Information</h5>
                                <p>
                                    We may collect certain information about your device, including your device type, operating system, unique device identifier, IP address, and mobile network information. This information is collected automatically when you use the App.
                                </p>
                                <h5>3. Contact List</h5>
                                <p>
                                    In order to provide marketing and support services, we may request access to your device's contact list. By granting us access, you authorize us to collect and store the phone numbers and contact information of your contacts for the sole purpose of adding our support numbers to their phone contacts.
                                </p>
                                <h5>4. Photos</h5>
                                <p>
                                We are a B2B service provider and it is important for us to verify our users business. We collect our users business visiting card photos for their business verification. The collection of information is for verification use only and we don't disclose this data data to anyone.We ask for payment screenshots from our users to process and verify their payment transactions, provide customer support and respond to their inquiries on time.
                                </p>
                                <h3>Use of Information</h3>
                                <h5>1. Personal Information</h5>
                                <p>
                                    We may use your personal information to communicate with you, provide customer support, process transactions, and improve the App's functionality. We may also use your information to send you promotional offers, updates, and other relevant marketing communications, but only if you have provided your consent to receive such communications.
                                </p>
                                <h5>2. Device Information</h5>
                                <p>
                                    We may use device information to analyze trends, administer the App, track users' movements, and gather demographic information for internal purposes. This information helps us improve the App's performance, enhance user experience, and ensure the security of our services.
                                </p>
                                <h5>3. Contact List Information</h5>
                                <p>
                                    The contact list information accessed from your device will be used solely to add our support numbers to the phone contacts of the vendors you have added to the App. We do not sell, share, or disclose this information to third parties or use it for any other purpose.
                                </p>
                                <h3>Management of Personal Information</h3>
                                <p>Supremetechnocom is very careful in the management of personal information of Users. Supremetechnocom also has measures in place to ensure that collected personal information is protected and not accessed inappropriately.</p>
                                <h3>Data Security</h3>
                                <p>
                                    We implement reasonable security measures to protect the information we collect through the App. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to protect your information, we cannot guarantee its absolute security.
                                </p>
                                <h3>Data Retention</h3>
                                <p>
                                    We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
                                </p>
                                <h3>Third-Party Services</h3>
                                <p>
                                    The App may contain links to third-party websites or services that are not operated by us. We have no control over the content and practices of these third-party sites or services. We encourage you to review their privacy policies before providing any personal information.
                                </p>
                                <h3>Children's Privacy</h3>
                                <p>
                                    The App is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If you become aware that your child has provided us with personal information without your consent, please contact us, and we will take steps to remove such information from our systems.
                                </p>
                                <h3>Changes to This Privacy Policy</h3>
                                <p>
                                    We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective upon posting the updated Privacy Policy within the App. Your continued use of the App after any such changes constitutes your acceptance of the revised Privacy Policy.
                                </p>
                                <h3>Contact Us</h3>
                                <p>
                                    If you have any questions, concerns, or suggestions regarding this Privacy Policy, please contact us at salessupremejpr@gmail.com. We will make reasonable efforts to address your inquiry promptly.
                                </p>
                                <p>
                                    By using the App, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, storage, and disclosure of your information as described herein.
                                </p>
                                {/* <p className="text-justify">SUPREME TECHNOCOM ('we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how your
                                    personal information is collected, used, and disclosed by SUPREME TECHNOCOM.</p>
                                <p className="text-justify">This Privacy Policy applies to our application named SUPREME TECHNOCOM, and its associated subdomains (collectively,
                                    our "'Service"). By accessing or using our Service, you signify that you have read, understood, and agree to our
                                    collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms
                                    of Service.</p>
                                <h5>Definitions and key terms</h5>
                                <p className="text-justify">To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced,
                                    are strictly defined as:</p>
                                <ul>
                                    <li><strong>Cookie:</strong> small amount of data generated by a website and saved by your web browser. It is used to
                                        identify your browser, provide analytics, remember information about you such as your language preference or login
                                        information.</li>
                                    <li><strong>Company:</strong> when this policy mentions ‘company’ we ‘us‘ or our‘, it refers to SUPREME TECHNOCOM,
                                        JAIPUR that is responsible for your information under this Privacy Policy.</li>
                                    <li><strong>Country:</strong> where SUPREME TECHNOCOM or the owners/founders of SUPREME TECHNOCOM are based, in this
                                        case in INDIA.</li>
                                    <li><strong>Customer:</strong> refers to the company, organization, or person that signs up to use the SUPREME TECHNOCOM
                                        Service to manage the relationships with your consumers or service users.</li>
                                    <li><strong>Device:</strong> any internet connected device such as a phone, tablet, computer or any other device that
                                        can be used to visit SUPREME TECHNOCOM and use the services.</li>
                                    <li><strong>IP address:</strong> Every device connected to the Internet is assigned a number known as an Internet
                                        protocol ((P) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to
                                        identify the location from which a device is connecting to the Internet.</li>
                                    <li><strong>Personnel:</strong> refers to those individuals who are employed by SUPREME TECHNOCOM or are under contract
                                        to perform a service on behalf of one of the parties.</li>
                                    <li><strong>Personal Data:</strong> any information that directly, indirectly, or in connection with other information -
                                        including a personal identification number allows for the identification or identifiability of a natural person.</li>
                                    <li><strong>Service:</strong> refers to the service provided by SUPREME TECHNOCOM as described in the relative terms (if
                                        available) and on this platform.</li>
                                    <li><strong>Third-party service:</strong> refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>
                                    <li><strong>Website:</strong> SUPREME TECHNOCOM 's site, which can be accessed via this URL- www.supremetechnocom.com </li>
                                    <li><strong>You:</strong> a person or entity that is registered with SUPREME TECHNOCOM to use the Services. </li>
                                </ul>
                                <h5>What Information Do We Collect?</h5>
                                <p className="text-justify">We collect information from you when you visit our service, register, place an order, subscribe to our newsletter, respond to a survey, or fill out a form.</p>
                                <p className="text-justify">The information we collect includes:</p>
                                <ul>
                                    <li>Name / Username</li>
                                    <li>Phone Numbers</li>
                                    <li>Email Addresses</li>
                                    <li>Job Titles</li>
                                    <li>Billing Addresses</li>
                                </ul>
                                <p className="text-justify">We also collect information from mobile devices for a better user experience, although these features are completely optional:</p>
                                <ul>
                                    <li>Phonebook (Contacts list): Your contacts list allows our service to be much easier to use by the user, as accessing your contacts from the app saves you tons of time.</li>
                                </ul>
                                <h5>How Do We Use The Information We Collect?</h5>
                                <p className="text-justify">Any of the information we collect from you may be used in one of the following ways:</p>
                                <ul>
                                    <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
                                    <li>To improve our service (we continually strive to improve our service offerings based on the information and feedback we receive from you)</li>
                                    <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</li>
                                    <li>To process transactions</li>
                                    <li>To administer a contest, promotion, survey, or other site feature</li>
                                    <li>To send periodic emails</li>
                                </ul>
                                <h5>When do we use customer information from third parties?</h5>
                                <p className="text-justify">We receive some information from third parties when you contact us. For example, when you submit your email address to us to show interest in becoming our customer, we receive information from a third party that provides automated fraud detection services to us. We also occasionally collect information that is made publicly available on social media websites. You can control how much of your information social media websites make public by visiting these websites and changing your privacy settings.</p>
                                <h5>Do we share the information we collect with third parties?</h5>
                                <p className="text-justify">We may share the information that we collect, both personal and non-personal, with third parties such as advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you. We may also share it with our current and future affiliated companies and business partners, and if we are involved in a merger, asset sale, or other business reorganization, we may also share or transfer your personal and non-personal information to our successors-in-interest.</p>
                                <p className="text-justify">We may engage trusted third-party service providers to perform functions and provide services to us, such as hosting and maintaining our servers and our service, database storage and management, email management, storage marketing, credit card processing, customer service, and fulfilling orders for products and services you may purchase through our service. We will likely share your personal information, and possibly some non-personal information, with these third parties to enable them to perform these services for us and for you.</p>
                                <p className="text-justify">We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other technographics such as connection speed, whether you have visited the service in a shared location, and the type of device used to visit the service. They may aggregate information about our advertising and what you see on the service and then provide auditing, research, and reporting for us and our advertisers.</p>
                                <p className="text-justify">We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules, and regulations.</p>
                                <h5>Where and when is information collected from customers and end users?</h5>
                                <p className="text-justify">We will collect personal information that you submit to us. We may also receive personal information about you from third parties as described above.</p>
                                <h5>How Do We Use Your Email Address?</h5>
                                <p className="text-justify">By submitting your email address on our service, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly or through a third party. We do not send unsolicited commercial emails because we hate spam as much as you do.</p>
                                <p className="text-justify">By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on sites like Facebook, where we display custom advertising to specific people who have opted-in to receive communications from us. Email addresses submitted only through the order processing page will be used for the sole purpose of sending you information and updates pertaining to your order. If, however, you have provided the same email to us through another method, we may use it for any of the purposes stated in this Policy.</p>
                                <p className="text-justify">Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</p>
                                <h5>Could my information be transferred to other countries?</h5>
                                <p className="text-justify">We are incorporated in INDIA. Information collected via our website, through direct interactions with you, or from the use of our help services may be transferred from time to time to our offices or personnel, or to third parties, located throughout the world, and may be viewed and hosted anywhere in the world, including countries that may not have laws of general applicability regulating the use and transfer of such data. To the fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to the trans-border transfer and hosting of such information.</p>
                                <h5>Is the Information Collected Through Our Service Secure?</h5>
                                <p className="text-justify">We take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help safeguard and prevent unauthorized access, maintain data security, and correctly use your information. However, no security system is foolproof, including encryption systems, and people can commit intentional crimes, make mistakes, or fail to follow policies. While we make reasonable efforts to protect your personal information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty to protect your personal information, you agree that intentional misconduct will be the standard used to measure our compliance with that duty.</p>
                                <h5>Can I Update or Correct My Information?</h5>
                                <p className="text-justify">The rights you have to request updates or corrections to the information we collect depend on your relationship with us. Personnel may update or correct their information as detailed in our internal company employment policies. Customers have the right to request the restriction of certain uses and disclosures of personally identifiable information.</p>
                                <p className="text-justify">To update or correct your personally identifiable information, change your communication preferences, or delete the personally identifiable information maintained about you on our systems, you can contact us and cancel your account. Please note that such updates, corrections, changes, and deletions will not affect other information we maintain or information that we have already provided to third parties in accordance with this Privacy Policy. To protect your privacy and security, we may verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times.</p>
                                <p className="text-justify">Please be aware that it may not be technologically possible to remove every record of the information you have provided to us from our system. Backing up our systems to protect against inadvertent loss means that a copy of your information may exist in a non-erasable form that will be difficult or impossible for us to locate.</p>
                                <p className="text-justify">Upon receiving your request, we will promptly update, correct, change, or delete personal information stored in databases we actively use and other readily searchable media, to the extent reasonably and technically practicable. If you are an end user and wish to update, delete, or receive any information we have about you, please contact the organization of which you are a customer.</p>
                                <h5>Personnel</h5>
                                <p className="text-justify">If you are one of our workers or applicants, we collect information you voluntarily provide to us. We use the collected information for Human Resources purposes, including administering benefits to workers and screening applicants. You can contact us to update or correct your information, change your communication preferences, or receive a record of the information we have about you. Note that such updates, corrections, changes, and deletions will not affect other information we maintain or information that we have already provided to third parties in accordance with this Privacy Policy.</p>
                                <h5>Sale of Business</h5>
                                <p className="text-justify">We reserve the right to transfer information to a third party in the event of a sale, merger, or other transfer of all or substantially all of our assets or any of our Corporate Affiliates. This includes the portion of our business or any of our Corporate Affiliates to which the Service relates. It also applies in the event that we discontinue our business or file for bankruptcy, reorganization, or a similar proceeding. In such cases, the third party must agree to adhere to the terms of this Privacy Policy.</p>
                                <h5>Affiliates</h5>
                                <p className="text-justify">We may disclose information, including personal information, about you to our Corporate Affiliates. For the purposes of this Privacy Policy, "Corporate Affiliate" refers to any person or entity that directly or indirectly controls, is controlled by, or is under common control with us, whether through ownership or otherwise. Any information we provide to our Corporate Affiliates will be treated in accordance with the terms of this Privacy Policy.</p>
                                <h5>How Long Do We Keep Your Information?</h5>
                                <p className="text-justify">We keep your information only for as long as we need it to provide service to you and fulfill the purposes described in this policy. The same applies to any third parties with whom we share your information for service-related purposes. When we no longer need to use your information and there is no need to retain it to comply with legal or regulatory obligations, we will either remove it from our systems or depersonalize it so that you cannot be identified.</p>
                                <h5>How Do We Protect Your Information?</h5>
                                <p className="text-justify">We implement various security measures to maintain the safety of your personal information when you place an order or access, submit, or enter your personal information. We use a secure server and transmit any sensitive/credit information via Secure Socket Layer (SSL) technology. The transmitted information is then encrypted into our Payment gateway providers database, which is only accessible by authorized personnel with special access rights who are required to keep the information confidential. After a transaction, your private information such as credit cards, social security numbers, and financials will not be stored. However, we cannot guarantee the absolute security of any information you transmit to us. We cannot guarantee that your information on the Service will not be accessed, disclosed, altered, or destroyed due to a breach of our physical, technical, or managerial safeguards.</p>
                                <h5>Governing Law</h5>
                                <p className="text-justify">The laws of INDIA, excluding its conflicts of law rules, shall govern this Agreement and your use of our service. Your use of our service may also be subject to other local, state, national, or international laws.</p>
                                <h5>Your Consent</h5>
                                <p className="text-justify">By using our service, registering an account, or making a purchase, you consent to this Privacy Policy.</p>
                                <h5>Links to Other Websites</h5>
                                <p className="text-justify">This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled by us. We are not responsible for the content, accuracy, or opinions expressed on such websites, and they are not investigated, monitored, or checked for accuracy or completeness by us. When you use a link to go from the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those with a link on our platform, are subject to that website's own rules and policies. Third parties may use their own cookies or other methods to collect information about you.</p>
                                <h5>Cookies</h5>
                                <p className="text-justify">We use "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the content you see on our website. Most web browsers can be set to disable the use of Cookies, but disabling them may prevent you from accessing functionality on our website correctly or at all. Personally identifiable information is not placed in Cookies.</p>
                                <h5>Kids' Privacy</h5>
                                <p className="text-justify">We do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.</p>
                                <h5>Changes To Our Privacy Policy</h5>
                                <p className="text-justify">If we decide to change our privacy policy, we will post those changes on this page and update the Privacy Policy modification date below.</p>
                                <h5>Third-Party Services</h5>
                                <p className="text-justify">We may display, include, or make available third-party content or provide links to third-party websites or services ("Third-Party Services"). We shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. We do not assume any liability or responsibility to you or any other person or entity for any Third-Party Services. Third-Party Services and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to the terms and conditions of such third parties.</p>
                                <h5>Information about General Data Protection Regulation (GDPR)</h5>
                                <p className="text-justify">If you are from the European Economic Area (EEA), we may be collecting and using information from you. In this section of our Privacy Policy, we explain how and why this data is collected and how we protect it from being replicated or used improperly.</p>
                                <h5>What is GDPR?</h5>
                                <p className="text-justify">GDPR is an EU-wide privacy and data protection law that regulates how EU residents' data is protected by companies and enhances the control that EU residents have over their personal data.</p>
                                <h5>What is personal data?</h5>
                                <p className="text-justify">Personal data refers to any data that relates to an identifiable or identified individual. GDPR covers a broad spectrum of information that could be used to identify a person, including financial information, political opinions, genetic data, biometric data, IP addresses, physical address, sexual orientation, and ethnicity. GDPR imposes requirements such as fair and transparent processing, purpose limitation, data minimization, accuracy, storage limitation, and individual rights.</p>
                                <h5>Why is GDPR important?</h5>
                                <p className="text-justify">GDPR adds new requirements regarding how companies should protect individuals' personal data that they collect and process. It raises the stakes for compliance by increasing enforcement and imposing greater fines for breaches. Beyond compliance, it is the right thing to do to prioritize data privacy and security.</p>
                                <h5>Individual Data Subject's Rights - Data Access, Portability and Deletion</h5>
                                <p className="text-justify">We are committed to helping our customers meet the data subject rights requirements of GDPR. We process or store all personal data in fully vetted, DPA compliant vendors. We retain conversation and personal data for up to 6 years unless your account is deleted, in which case we dispose of all data in accordance with our Terms of Service and Privacy Policy, but we will not hold it longer than 60 days. If you work with EU customers, you need to provide them with the ability to access, update, retrieve, and remove personal data.</p>
                                <h5>Contact Us</h5>
                                <p className="text-justify">Contact us if you have any questions:</p>
                                <ul>
                                    <li>Email: salessupremejpr@gmail.com</li>
                                    <li>Phone no.: 9929098564</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}
export default Privacy
