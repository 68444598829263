import axios from "axios";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { baseUrl } from "./baseUrl";

export default function OrderDetails() {
  // Getting id from page url
  const api_id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  const [orderDetail, setOrderDetail] = useState([]);
  useEffect(() => {
    const fetchOrders = async () => {
      const orderDetailData = new FormData();
      orderDetailData.append("token_id", localStorage.getItem("user_token"));
      orderDetailData.append("phone", localStorage.getItem("supremetech_phone"));
      orderDetailData.append("authentication", localStorage.getItem("supremetech_authentication"));
      
      orderDetailData.append("order_id", api_id);
      try {
        const response = await axios.post(
          baseUrl + "Apicontroller/orderdetail",
          orderDetailData
        );
        console.log("order detail", response);
        if (response.data.status === 200) {
          setOrderDetail(response.data.data);
        }
      } catch {
        console.log("Issue in order detail");
      }
    };
    fetchOrders();
  }, []);
  if (
    !(localStorage.getItem("supremetech_authentication") === "" || localStorage.getItem("supremetech_authentication") === null)
  ) {
    return (
      <div>
        <section class="mt-5 mb-5 pt-5 pb-5">
          <div class="container-fluid">
            <div class="row heading-div">
              <div class="col-md-12 text-center">
                <h1 className="heading" style={{marginBottom:"0px"}}>ORDER DETAILS</h1>
            <img src="../img/bottom.png" style={{width:"13rem",marginTop:"-10px",marginBottom:"10px"}}></img>

              </div>
            </div>
            <div class="row justify-content-center">
              {/* <div class="col-md-12">
					<p>Your selection <b>( 1 item )</b></p>
				</div> */}
              <div class="col-md-9">
                <table class="cart_table text-center">
                  <tr
                    class="mb-4 mb-lg-0 not_show"
                    style={{ background: "#edeee6" }}
                  >
                    <th class="pt-2 pb-2"></th>
                    <th class="pt-2 pb-2"></th>
                    <th class="pt-2 pb-2">PRODUCT</th>
                     {/*<th class="pt-2 pb-2">PRICE</th>*/}
                    <th class="pt-2 pb-2">QUANTITY</th>
                    {/* <th class="pt-2 pb-2">TYPE NAME</th> */}
                    <th class="pt-2 pb-2">Amount</th>
                  </tr>
                  {orderDetail
                    ? orderDetail.map((elem, i) => {
                        return (
                          <tr class="mb-4 mb-lg-0">
                            <td class="pl-1">
                              {/* // <img   style={{width:"25px"}} src="assets\img/cut.png"/> */}
                            </td>
                            <td>
                              <img class="pt-3 pb-3" src={elem.product_image} />
                            </td>
                            <td style={{ width: "228px" }}>
                              {elem.product_name}
                            </td>
                           {/* <td>
                              <span>
                                <i className="fa fa-inr"></i>{" "}
                              </span>
                              {elem.price}
                           </td>*/}
                            <td>
                              {/* <div class="cart-plus-minus"> */}
                              <span>{elem.quantity}</span>
                              {/* </div> */}
                            </td>
                            <td> <i className="fa fa-inr"></i>{" "}{elem["total amount"]}</td>
                          </tr>
                        );
                      })
                    : ""}
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}
