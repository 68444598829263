import React, { useEffect } from "react";
import { Redirect } from "react-router";

export default function Profile(props) {
  useEffect(() => {
    const authentication = localStorage.getItem("supremetech_authentication");
    const phone = localStorage.getItem("supremetech_phone");
    if (authentication === "" && phone === "") {
      props.history.push("/");
    }
  }, [
    localStorage.getItem("supremetech_authentication"),
    localStorage.getItem("supremetech_phone"),
  ]);
  if (
    !(localStorage.getItem("supremetech_authentication") === "" || localStorage.getItem("supremetech_authentication") === null)
  ) {
    return (
      <div class="container pt-5 pt-lg-5 mt-md-5 mt-4">
        <div className="row heading-div">
          <div className="col-md-12 text-center">
            <h1 className="heading" style={{marginBottom:"0px"}}>My ACCOUNT</h1>
            <img src="../img/bottom.png" style={{width:"13rem",marginTop:"-10px",marginBottom:"10px"}}></img>
          </div>
        </div>
        <div class="main-body">
          <div class="row gutters-sm">
            <div class="col-md-8">
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Full Name</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      {localStorage.getItem("supremetech_name")}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-3">
                      <h6 class="mb-0">Phone</h6>
                    </div>
                    <div class="col-sm-9 text-secondary">
                      {localStorage.getItem("supremetech_phone")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/register" />;
  }
}
