import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { baseUrl } from "./baseUrl";
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'

export default function MyOrders(props) {
  const [orderList, setOrderList] = useState([]);
  const [temp_order_id, settemp_order_id]=useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal=(order_id)=> {
    setIsModalOpen(true);
    settemp_order_id(order_id)
  }
  useEffect(() => {
    const fetchOrders = async () => {
      const orderData = new FormData();
      orderData.append("token_id", localStorage.getItem("user_token"));
      orderData.append("phone", localStorage.getItem("supremetech_phone"));
      orderData.append("authentication", localStorage.getItem("supremetech_authentication"));
      for (let [key, value] of orderData) {
        console.log("View Order ka data", key, ":", value);
      }
      try {
        const response = await axios.post(
          baseUrl + "Apicontroller/view_order/",
          orderData
        );
        console.log("view order", response);
        if (response.data.status === 200) {
          setOrderList(response.data.data);
        }
      } catch {
        console.log("Issue in view order");
      }
    };
    fetchOrders();
  }, []);
  const cancleOrder = async (id) => {
    const cancleOrderData = new FormData();
    cancleOrderData.append("token_id", localStorage.getItem("user_token"));
      cancleOrderData.append("phone", localStorage.getItem("supremetech_phone"));
      cancleOrderData.append("authentication", localStorage.getItem("supremetech_authentication"));
      cancleOrderData.append("order_id", id);
    for (let pair of cancleOrderData) {
      console.log("cancle order:", pair[0], pair[1]);
    }
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/cancel_order/",
        cancleOrderData
      );
      console.log("cancle order", response);
      if(response.data.status===200){
        props.changePopupText("Order Successfully canceled.")
      } else {
        props.changePopupText(response.data.message)
      }
    } catch {
      console.log("Issue in cancel order");
    }
  };
  if (
    !(localStorage.getItem("supremetech_authentication") === "" || localStorage.getItem("supremetech_authentication") === null)
  ) {
    return (
        <section class="container pt-5 pt-lg-5 mt-md-5 mt-4">
          <div class="container-fluid">
          <div className="row">
        <Modal isOpen={isModalOpen} backdrop={true} style={{top:"40%"}}>
            <ModalHeader>
                Alert!
            </ModalHeader>
            <ModalBody>
                <p>Are you sure you want to cancel this order?</p>
            </ModalBody>
            <ModalFooter>
                <button className="btn" style={{background: '#2b2b2b', color: 'white'}} onClick={()=> cancleOrder(temp_order_id)}>Yes</button>
                <button className="btn" style={{background: '#dc3545', color: 'white'}} onClick={()=> setIsModalOpen(false)}>No</button>
            </ModalFooter>
        </Modal>
        </div>
            <div class="row">
              <div class="col-md-12 text-center heading-div">
                <h1 className="heading" style={{marginBottom:"0px"}}>My ORDERS</h1>
            <img src="../img/bottom.png" style={{width:"13rem",marginTop:"-10px",marginBottom:"10px"}}></img>
              </div>
            </div>
            <div class="row">
              {/* <div class="col-md-12">  
              <p>
                Your selection <b>( 1 item )</b>
              </p>
            </div> */}
              <div class="col-md-12">
                <table class="cart_table">
                  {
                    orderList ? (
                      orderList.map((elem, i) => {
                        console.log("orderList, elem", orderList, elem);
                        return (
                          <>
                            <tr class="mb-4 mb-lg-0">
                              <th class="p-2">
                                <span>Order Id #{elem.order_id}</span>
                              </th>
                              <th class="p-2">
                                <span
                                  className={"badge-pill badge p2"+ elem.order_status==="Placed"?"badge-pill badge-danger":elem.order_status==="Canceled"?" badge-pill badge-danger":elem.order_status==="Confirmed"?" badge-pill badge-secondary":elem.order_status==="Hold"?" badge-pill badge-warning":elem.order_status==="Dispatched"?" badge-pill badge-info":elem.order_status==="Delivered"?" badge-pill badge-success":" badge-pill badge-primary"}
                                  style={{ color: "white"}}
                                >
                                  {elem.order_status}
                                </span>
                              </th>
                              <th class="p-2">{elem.order_date}</th>
                            </tr>
                            <tr class="mb-4 mb-lg-0">
                              {/* <td>final_amount: null</td> */}
                              <td>Payment Type: {elem.payment_type}</td>
                              {/* <td>Promocode Discount: {elem.discount!==null?elem.discount:0}</td> */}
                              <td> Total Weight: {" "}
                                {elem.weight}Gm</td>
                              <td>
                                Total Amount: <i className="fa fa-inr"></i>{" "}
                                {elem.total_amount}
                              </td>
                              <td></td>
                              <td>
                                <div className="d-flex flex-column text-center">
                                  <div className="m-1">
                                    {elem.cancel_status === 1 ? (
                                      <button
                                        onClick={() =>
                                          openModal(elem.order_id)
                                        }
                                        className="btn"
                                        style={{
                                          background: "#dc3545",
                                          color: "white",
                                        }}
                                      >
                                        <i className="fa fa-times-circle"></i>{" "}
                                        Cancel Order
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="m-1">
                                    <Link
                                      to={{
                                        pathname: `/order-details/${elem.order_id}`,
                                      }}
                                    >
                                      <button
                                        className="btn"
                                        style={{
                                          background: "#2b2b2b",
                                          color: "white",
                                        }}
                                      >
                                        View Order
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No order placed</td>
                      </tr>
                    )
                    // (<div style={{position: 'absolute',left: '45%'}}>No order placed.</div>)
                  }
                </table>
                {orderList.length === 0 ? (
                  <div className="text-center">No order placed.</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
    );
  } else {
    return <Redirect to="/" />;
  }
}
