import React from 'react'
import { Link } from 'react-router-dom'

export default function Fail() {
    return (
        <>
      {/* <br /> */}
      <div className="container pt-5 mt-5">
            <div className="row pt-5 mt-5">
          <div className="col-md-12">
            <h2 style={{ textAlign: "center" }}> &#x274C;</h2>
            <h1 style={{ textAlign: "center", color: "red" }}> Sorry</h1>
            <p style={{ textAlign: "center", color: "red" }}>
              {" "}
              Order is failed to be placed , please make sure you follow the checkout instruction carefully...
            </p>
          </div>
        </div>
        <div className="row">
              <div style={{ display: "flex" }} className="col-md-12">
                <div className="col-md-6 text-center" style={{ width: "50%" }}>
                  <Link to="myorders"><button className="btn" style={{ background: "rgb(220, 53, 69)", color: "white"}}>View Order</button></Link>
                </div>

                <div className="col-md-6 text-center" style={{ width: "50%" }}>
                  <Link to="/"><button className="btn" style={{ background: "rgb(37 35 35)", color: "white"}}>Continue Shopping</button></Link>
                </div>
                {/* </a> */}
              </div>
            </div>
      </div>
    </>
    )
}
