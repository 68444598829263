import React, { useState } from "react";
function Contactus() {
  const [contact, setContact] = useState("")
  const validateSignupForm = (e) => {
      const reg = /^\d+$/;
      if (reg.test(e.target.value) || e.target.value === "") {
        setContact(e.target.value);
      }
  };
    return (
        <div>
  <section className="pt-50 client-main align-center" style={{marginTop:'6rem'}}>
    <div className="container">
      <div className="contact-info">
        <div className="row m-0">
          <div className="col-sm-12 p-0 mb-3 text-center">
          <div className="contact-icon contact-phone-icon  mb-3"><i className="fa fa-user" /> <span><b>Order Releted Query</b></span></div>
          <p><i className="fa fa-phone" /> <a href="tel:7627040986">7627040986</a></p>
          </div>
          <div className="col-sm-12 p-0 mb-3">
              <div className="contact-icon contact-phone-icon text-center mb-3"><i className="fa fa-user" /> <span><b>Technical Support & Other Product Enquiry</b></span></div>
            <div className="row ">
            <div className="contact-box change col-md-4">
           <ul className="pl-0">
            <li><i className="fa fa-phone" /> <a href="tel:18001238564">COREPRIX-   18001238564</a></li>
            <li><i className="fa fa-phone" /> <a href="tel:18001035719">DAHUA -     18001035719</a></li>
            <li><i className="fa fa-phone" /> <a href="tel:1800222699">HIKVISION - 1800222699</a></li>
           </ul>
            </div>
            <div className="contact-box change col-md-4">
            <ul className="pl-0">
            <li><i className="fa fa-phone" /> <a href="tel:8800952952">CP PLUS -    8800952952</a></li>
            <li><i className="fa fa-phone" /> <a href="tel:18003261688">D-LINK -      18003261688</a></li>
            <li><i className="fa fa-phone" /> <a href="tel:18002091234">PRAMA -      18002091234</a></li>
           </ul>
            </div>
            <div className="contact-box change col-md-4">
            <ul className="pl-0">
            <li><i className="fa fa-phone" /> <a href="tel:01141608572">TENDA-         011-41608572</a></li>
            <li><i className="fa fa-phone" /> <a href="tel:18001022366">SECURE EYE -18001022366</a></li>
            <li><i className="fa fa-phone" /> <a href="tel:18003092525">SEAGATE -    18003092525</a></li>
            <li><i className="fa fa-phone" /> <a href="tel:18001205789">WESTERN DIGITAL-  18001205789</a></li>
           </ul>
            </div>
            </div>
          </div>
          <div className="col-sm-4 p-0">
            <div className="contact-box change">
              <div className="contact-icon contact-phone-icon"><i className="fa fa-phone" /> <span><b>Phone Number</b></span></div>
             <span style={{fontWeight:500}}>Supreme Technocom (Rajasthan)</span>
              <p><a href="tel:9929098564">9929098564</a> , <a href="tel:7627040986">7627040986</a> , <a href="tel:9660582928">9660582928</a>,<br></br><a href="tel:01416769196">0141-6769196</a></p>
             <span style={{fontWeight:500}}>Supreme Technocom (Gujrat)</span>
              <p><a href="tel:9251634315">9251634315</a>, <a href="tel:9251634316">9251634316</a>, <a href="tel:9251634317">9251634317</a>, <a href="tel:9251634318">9251634318</a></p>
            </div>
          </div>
          <div className="col-sm-4 p-0">
            <div className="contact-box change">
              <div className="contact-icon contact-mail-icon"><i className="fa fa-envelope-open" /> <span><b>E-mail Address</b></span></div>
              <p><a href="mailto:salessupremejpr@gmail.com">salessupremejpr@gmail.com</a> </p>
            </div>
          </div>
          <div className="col-sm-4 p-0">
            <div className="contact-box change">
              <div className="contact-icon contact-open-icon"><i className="fa fa-map-marker" /> <span><b>Our Address</b></span></div>
             <span style={{fontWeight:500}}>Supreme Technocom (Rajasthan)</span>
              <p>P.NO.262 - Basement, Yadav Marg, Gate No. 3, Sector 9 Opp., Akshardham Temple, Chitrakoot, Vaishali Nagr, Jaipur (302021)</p>
             <span style={{fontWeight:500}}>Supreme Technocom (Gujrat)</span>
             <p>A-18, A Block, Ground Floor, Saedar Patel Mall Nikol Gam Road, Ahemdabad (Gujrat)</p>
              <p>Love Kush, KK Nagar Road, Patidar Chowk, Ghatlodia, Ahemdabad, 380061</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div className="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4231.248626938351!2d75.73878559939486!3d26.902168404363312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db327124e49a7%3A0x2cb62a2a314f98bf!2sSupreme%20Technocom!5e0!3m2!1sen!2sin!4v1648882667306!5m2!1sen!2sin" width="100%" height="500px" style={{border: 0,marginTop:'2.2rem'}} allowFullScreen loading="lazy" />
    </div>
  </section>
  {/* <section className="mt-5 mb-5 pt-lg-5 mt-md-5 pb-5">
  <div className="container-fluid">
      <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 checkout_colm">
          <p id="checkoutError" className="alert alert-secondary alert-dismissible fade show mb-4">
          Please fill the required details.
      </p>
              <form className="d-block">
                  <label className="d-block">Full Name <sub>*</sub></label>
                  <input type="text" name="full__name" required/>
                  <label className="d-block">Message  <sub>*</sub></label>
                  <textarea cols="50" rows="3" name="message" required></textarea>
                  <label className="d-block">Enquiry  <sub>*</sub></label>
                  <textarea cols="50" rows="3" name="enquiry" required></textarea>  
                  <label className="d-block">Phone Number <sub>*</sub></label>
                  <input type="text" name="phone"  value={contact}
                      maxLength="10"
                      minLength="10"
                      required
                      onChange={(e)=>validateSignupForm(e)} />
                  <button type="submit"
                  style={{
                      background: "#d7314a",
                      color: "white",
                    }}
                  class="btn mt-3">Submit</button>
              </form>
          </div>
      </div> 
  </div>
</section> */}
</div>
    )
}
export default Contactus
