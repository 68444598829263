
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ProductCard from "./ProductCard";
import { baseUrl } from "./baseUrl";
import { Redirect } from "react-router";

export default function WishList(props) {
  const [wishlistDetails, setWD] = useState([]);
  // Get Cart Data function
  const getWishlistData = async () => {
    try {
      var getWishlistData = new FormData();
      getWishlistData.append("token_id", localStorage.getItem("user_token"));
      getWishlistData.append(
        "phone",
        localStorage.getItem("supremetech_phone")
      );
      getWishlistData.append(
        "authentication",
        localStorage.getItem("supremetech_authentication")
      );
      // alert("getWishlistData", getWishlistData);
      for (var pair of getWishlistData.entries()) {
        console.log("getWishlistData is heres", pair[0] + ", " + pair[1]);
      }
      const response = await axios.post(
        baseUrl + "Apicontroller/view_wishlist",
        getWishlistData
      );
      console.log("Wishlist Data:", response.data);
      if(response.data.data!==[]){
        setWD(response.data.data);
      }
    } catch {
      console.log("Issue in getting wishlist data");
    }
  };
  useEffect(() => {
    getWishlistData();
  }, [localStorage.getItem("user_token"), localStorage.getItem("supremetech_authentication"), localStorage.getItem("supremetech_phone")]);

  const moveCart = async (product_id) => {
    const removewishlistData = new FormData();
    removewishlistData.append("product_id", product_id);
    removewishlistData.append("token_id", localStorage.getItem("user_token"));
    removewishlistData.append("authentication", localStorage.getItem("supremetech_authentication"));
    removewishlistData.append("phone", localStorage.getItem("supremetech_phone"));

    try {
      // alert("Abhi remove cart call hua");
      const response = await axios.post(
        baseUrl + "Apicontroller/move_to_cart/",
        removewishlistData
      );
      console.log("Hogaya remove from wishlist", response);
      if (response.data.status === 200) {
        props.changePopupText(
          "Success! product successfully move from wishlist."
        );
        getWishlistData();
      }else{
        props.changePopupText(
          response.data.message
        );
      }
    } catch {
      console.log("Issue in delete wishlist data API");
    }
  };


  // delete product in cart
  const removeFromWishlist = async (product_id) => {
    const removewishlistData = new FormData();
      removewishlistData.append("product_id", product_id);
      removewishlistData.append("token_id", localStorage.getItem("user_token"));
      removewishlistData.append("authentication", localStorage.getItem("supremetech_authentication"));
      removewishlistData.append("phone", localStorage.getItem("supremetech_phone"));
      for (let [key, value] of removewishlistData) {
        console.log("Wishlist ka data", key, ":", value);
      }
    try {
      // alert("Abhi remove cart call hua");
      const response = await axios.post(
        baseUrl + "Apicontroller/remove_wishlist_product/",
        removewishlistData
      );
      console.log("Hogaya remove from wishlist", response);
      if (response.data.status === 200) {
        props.changePopupText(
          "Success! product successfully deleted from wishlist."
        );
        getWishlistData();
      }
    } catch {
      console.log("Issue in delete wishlist data API");
    }
  };
  if(
    !(localStorage.getItem("supremetech_authentication") === "" || localStorage.getItem("supremetech_authentication") === null)
  ) {
  return (
    <section className="filter_img pt-lg-5 mt-md-5 mt-5 pt-5">
      <div className="container">
        <div className="row">
          <div class="col-md-12 text-center heading-div">
            <h1 className="heading" style={{marginBottom:"0px"}}>MY WISHLIST</h1>
            <img src="../img/bottom.png" style={{width:"13rem",marginTop:"-10px",marginBottom:"10px"}}></img>
          </div>
        </div>

        <div className="row">
          {wishlistDetails.length!==0 ? (
            wishlistDetails.map((elem, i) => {
              console.log(elem);
              return (
                <div className="col-lg-3 col-md-3 col-12 mb-4">
                  <a
                    className="remove_wish"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      removeFromWishlist(elem.product_id, elem.type_id)
                    }
                  >
                    <img src="../img/cut.png" />
                  </a>
                  <div className="bor_ra">
                    <Link
                      to={{
                        pathname: `/product-details/${elem.product_id}`,
                        state: elem.product_id,
                      }}
                    >
                      {/* <img
                        src={elem.product_image}
                        className="product-card-img"
                        alt={elem.product_name}
                      /> */}
                      { elem.stock===0 ?
                <img
                  className="product-card-img"
                  src={elem.product_image}
                  alt={elem.productname}
                  style={{ height: "15rem",opacity: 0.5 }}
                />
                :
                <img
                  className="product-card-img"
                  src={elem.product_image}
                  alt={elem.productname}
                  style={{ height: "15rem"}}
                />
                }
              {elem.stock===0 ?
                <div style={{
                  position: 'absolute',
                  color:'white',
                  textAlign:'center',
                  top: '30%',
                  right: '30%',
                  left: '30%',
                  background:"#d7314a",
                  // width:"100%"
                }}>
                  <span>Out of Stock</span>
                </div>
              :null}
                    </Link>
                    <p>
                      <small>
                        {elem.product_name.length > 23
                          ? elem.product_name.substring(0, 23) + "..."
                          : elem.product_name}
                      </small>
                    </p>
                    {/*<div className="price_box_cut text-center">
                      <del>
                        <span>
                          <i className="fa fa-inr"></i> {elem.product_mrp}
                        </span>
                      </del>
                    </div>*/}
                    <div className="price_box">
                      <p>
                        <i className="fa fa-inr"></i> {elem.product_selling_price}
                      </p>
                    </div>
                    <div>
                      <small>
                        <button
                          className={"cart_btn"}
                          onClick={() =>
                            moveCart(elem.product_id, elem.type_id)
                          }
                        >
                          MOVE TO CART{" "}
                          <i className="fa fa-shopping-bag ml-2"></i>
                        </button>
                      </small>
                      {/* <AddToCart className={"cart_btn"} productDetail={props} styles={{}} /> */}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-12 col-md-12 text-center">
              Your wishlist is empty.
            </div>
          )}
        </div>
      </div>
    </section>
  );
} else {
  <Redirect to="/" />
}
}

