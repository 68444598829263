import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link } from "react-router-dom";
import { baseUrl } from "./baseUrl";

function Slider() {
  const [data, setData] = useState([]);

  const getslider = async () => {
    try {
      let response = await axios.get(baseUrl + "Apicontroller/get_slider");
      console.log("slider data:", response.data);
      if (response.data.status === 200) {
        setData(response.data.data);
      }
    } catch {
      console.log("Issue in getslider.");
    }
  };

  useEffect(() => getslider(), []);

  return (
    <div className="slider-outer">
      {/* top slider start */}
      <div id="demo" className="carousel slide" data-ride="carousel" enableSnap ="false"  autoplay data-interval="4000" data-pause="hover" >
        {/* Indicators */}
        <ul className="carousel-indicators">
        {data
            ? data.map((elem, i) => {
          return(
          <li data-target="#demo" data-slide-to={i} className={i===0?"active":""} />
            )}):null}
        </ul>
        {/* The slideshow */}
        <div className="carousel-inner">
          {data
            ? data.map((elem, i) => {
                if (i === 0) {
                  return (
                    <>
                      <div className="carousel-item active">
                        <img  src={elem.image} />
                      </div>
                    </>
                  );
                } else {
                  return (
                    <>
                      <div className="carousel-item ">
                        <img src={elem.image} />
                      </div>
                    </>
                  );
                }
              })
            : null}
        </div>
        {/* Left and right controls */}
        <a className="carousel-control-prev" href="#demo" data-slide="prev">
          <span className="carousel-control-prev-icon" />
        </a>
        <a className="carousel-control-next" href="#demo" data-slide="next">
          <span className="carousel-control-next-icon" />
        </a>
      </div>
      {/* top slider end */}
    </div>
  );
}

export default Slider;

