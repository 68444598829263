import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "./baseUrl";
import ProductCard from "./ProductCard"

export default function SeachedProducts(props) {
  const [searchResult, setSR] = useState([]);
  const searchStringdata = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
    const [authentication, setAuthentication] = useState("");
  const searchString =  searchStringdata.replace(/%20/g, ' ');
  const searchForProducts = async (e) => {
    // alert(searchText)
    const searchData = new FormData();
    searchData.append("string", searchString);
    try {
      const response = await axios.post(
        baseUrl+"Apicontroller/search_product",
        searchData
      );
      console.log("search response in header", response.data.status);
      if (response.data.status === 200) {
        setSR(response.data.data);
      }
      console(JSON.stringify(response.data.data));
    } catch {
      console.log("Issue in searching");
    }
  };
  useEffect(() => {
    searchForProducts();
  }, [searchString]);
  return (
    <section className="mt-5 pt-5 pt-lg-5 mt-md-5">
      <div className="container">
        <div class="row heading-div">
          <div class="col-md-12 text-center">
          <h2 className="heading" style={{marginBottom:"0px"}}>Showing Results For {searchString}</h2>
          <img src="../img/bottom.png" style={{width:"13rem",marginTop:"-10px",marginBottom:"10px"}}></img> 
          </div>
        </div>
        <div className="row">
            {
              searchResult
                ? searchResult.map((elem, i) => {
                    return (
                      <>
                      <ProductCard
                        product_id={elem.product_id}
                        productimage={elem.produt_image}
                        productname={elem.product_name}
                        mrp={elem.product_mrp}
                        stock={elem.stock}
                        price={elem.product_selling_price}
                        addingToCartFunction={props.addingToCart}
                        type_id={elem.type_id}
                      />
                    </>
                    );
                  })
                : "No result found."
            }
        </div>
        { searchResult.length===0?(
        <div className="row">
            <div className="col-md-12 col-12 text-center">
              No result found.
            </div>
        </div>):""
        }
      </div>
    </section>
  );
}
