import React from "react";
import "./Aboutus.css";

function AboutUs() {
  return (
    <>
    <section class="ptb-50 ptb-xs-30 gray-bg">
        <div class="container">
            <div class="row testimonial">
                <div class="col-md-12 sm-6">
                  <div className="text-center">
                <p className="MsoNormal mb-0" style={{textAlign: 'center', marginTop:"8em",}} align="center"><b style={{msoBidiFontWeight: 'normal'}}><span style={{fontSize: '28.0pt', msoBidiFontSize: '11.0pt', lineHeight: '115%', }}>About Us</span></b></p>
                <img src="../img/bottom.png" style={{width:"13rem",marginTop:"-10px",marginBottom:"10px"}}></img> 
                </div>
        <div style={{textAlign: 'justify'}}>
        <p>
          We Supreme Technocom, established in 2012 are well known distributor,
          trader, supplier and exporter of Digital & Analog Electronic
          Equipment. The offered range of products incorporates CCTV Cameras,
          Access Control System, Home Security Alarms, Video Door Phones, a
          complete range of accessories Security Door Frame and Electronic Safe.
          Offered security and surveillance systems are widely used in the
          offices, commercial areas, airports, railway stations and other
          places. Our offered products are highly efficient and user friendly.
          We have with us a huge and spacious warehousing unit that enables us
          to efficiently store our range of Security and Surveillance Systems.
          This unit is further segregated into various sections to accommodate
          our diversified range in a well structured and systematized manner.   
        </p>
        <p> Our warehouse is also equipped with various safety measures to protect
          our range from any type of damage from hazardous elements. Moreover,
          this unit is managed by our experienced inventory managers who keep
          proper records of our inward and outward stocks. This enables us to
          cater to the urgent and bulk requirements of our clients.</p>
          <p>In addition
          to this unit, we also have a packaging facility that takes care of the
          packaging requirements of our range. The packaging experts in this
          unit use quality packaging materials to pack the products. This
          ensures the safety of our products during storage as well as transit.
          We are a client-oriented organization and strive to offer our clients
          with a qualitative range of Security and Surveillance Systems. For
          this, procure these products from reputed manufacturers in the
          industry who assure us with optimum quality as well as functionality
          in our range. We also offer to our clients with various after sales
          services such as installation and user trainings. </p>
          <p>Our customer
          relationship management has made easier for us to include latest
          technology in our range, which has resulted in retaining our client
          base. Further, with our ethical business practices and easy payment
          options, we have garnered a huge clientele across the country. The
          main reason for our success in this domain is because of our huge
          vendor base, who supplies us with such qualitative range. Our vendors
          also help us in catering to the urgent and bulk requirements our
          clients within the agreed time frame. Moreover, we check our vendors
          on various parameters before their selection. Our team checks for
          their market credibility, ability to maintain a standard quality,
          financial status, and also their ability to customize these products
          as per the client’s requirements.</p>
          <p>Thus, with such a strong vendor base
          at our support, we have garnered an excellent response from our
          clients. A proficient team is the requirement for any organization to
          sustain in today’s competitive world. For this, we have employed team
          of experienced and expert personnel to execute our business
          operations. Our team comprises experienced procurement agents,
          engineers, technicians, quality personnel and sales & marketing
          executives. They have considerable knowledge of our domain and ensure
          to maintain our quality benchmark in all our activities. Further, we
          also emphasize on the internal & external communication, coordination
          and creativity of our team. This enables them to work as a unit and
          offer our clients with a range of supreme quality products with
          optimum functionality. </p>
          <p>We also arrange various training sessions for
          our team to enable them to keep pace with the changing market trends.
          We are one of the reputed organizations, offering a qualitative range
          of Security and Surveillance Systems to our clients. All these are
          sourced from reputed manufacturers in the industry who assure us with
          optimum performance in our range. We also stringently check our range
          at the time of procurement to ensure its compliance with various
          industry standards. Thus, our products have received huge appreciation
          from our clients for its characteristic features. Our company is
          committed to its clients, and has earned a favorable reputation in the
          markets with its high quality products and efficient services to its
          dealers. We are not bothered to compromise with the quality of our
          products. To bring best quality product we use high quality raw
          materials, sophisticated tools and cutting edge technology in the
          manufacture of these equipment. Also, we employ advanced machinery to
          manufacture best quality products.</p>
          <p> For ensuring their optimum quality,
          offered products are rigorously checked on various parameters by our
          qualified team of professionals. We always strive to bring latest and
          modern technology to provide our customers innovative and Hi- Tech
          products. Further, we have a highly qualified and trained group of
          experienced professionals, which continuously put its efforts for
          developing new products, to meet the requirements of clients. We
          thrive on qualities such as truthfulness, integrity, reliability and
          hard work that have resulted in the prosperity of every associate as
          well as in the exponential growth of the company itself. We have been
          able to set new benchmarks for the competitors. They always guides the
          people associated with us to reflect perfection in all the tasks
          assigned to them. Adhering to ethical business policies coupled with
          focus on client centric approach, we have established a reputed name
          as a leading manufacturer, suppliers and exporter of numerous security
          products.</p>
        </div>
      </div>
      </div>
      </div>
 </section>
   </>
  );
}

export default AboutUs;
