import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import { Redirect } from "react-router";
export default function RegisterOTP(props) {
  const [responeMessage, setResponseMessage] = useState("OTP sent successfully!");
  const sendRegisteredOTP = async (e) => {
    e.preventDefault();
    // alert(localStorage.getItem("supremetech_phone"))
    const emailData = new FormData(e.target);
    emailData.append("phone", localStorage.getItem("supremetech_phone"));
    for (let [key, value] of emailData) {
      console.log("register ka data", key, ":", value);
    }
    try {
      const response = await axios.post(
        baseUrl + "Users/register_otp_verify",
        emailData
      );
      console.log("otp register:", response);
      
      // console.log("otp register:", response);

      if (response.data.status === 200) {
        localStorage.setItem("supremetech_authentication", response.data.authentication)
        localStorage.setItem("supremetech_phone", response.data.phone);
        localStorage.setItem("supremetech_name", response.data.name);
        props.checkAndSetCredentials();
        props.changePopupText("User successfully registered and login.");
        setResponseMessage("User successfully registered and login.");
      } else {
        setResponseMessage(response.data.message);
      }
    } catch {
      console.log("Issue in register otp");
    }
  };
  const [OTP, setOTP] = useState("")
  const validateSignupForm = (e) => {
    const reg = /^\d+$/;
    if (reg.test(e.target.value) || e.target.value === "") {
      setOTP(e.target.value);
    }
};
  if (
    !localStorage.getItem("supremetech_phone") ||
    !localStorage.getItem("supremetech_authentication")
  ) {
    return (
      <section className="mt-5 mb-5 pt-5 pb-5">
        <div className="row heading-div">
          <div className="col-md-12 text-center">
            <h1 className="heading">OTP Verification</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-1 col-md-4"></div>
          <div className="col-10 col-md-4">
            <p
              id="checkoutError"
              className="alert alert-secondary alert-dismissible fade show mb-4"
            >
              {responeMessage}
            </p>
            <form
              className="form-block-holder"
              onSubmit={(e) => sendRegisteredOTP(e)}
            >
              <div className="form-group">
                <label for="otp">OTP</label>
                <input type="text"  maxLength="6" minLength="6" name="otp" required   value={OTP}  onChange={(e)=>validateSignupForm(e)} className="form-control" />
              </div>
              <div className="form-group">
                <input
                  type="submit"
                  className="btn w-50"
                  style={{ background: "#2b2b2b", color: "white" }}
                />
              </div>
            </form>
          </div>
          <div className="col-1 col-md-4"></div>
        </div>
      </section>
    );
  } else {
    return <Redirect to="/" />;
  }
}
