import axios from "axios";
import React, { useState, useEffect } from "react";
import { Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { baseUrl } from "./baseUrl";
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'

function Header(props) {
  const [category, setCategory] = useState([]);
  const [token, setToken] = useState([]);
  const [cartLength, setCL] = useState(0);
  const [wishLength, setWL] = useState(0);
  const [popUpMessage, setPUM] = useState("");
  const [phone, setPhone] = useState("");
  const [authentication, setAuthentication] = useState("");
  const [showPopUp, setSPU] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleShowPopUp = () => setSPU(!showPopUp);
  const lookForSearch = (e) => {
    //   console.log("Key clicked", e.key )
    //   alert(e.keyCode)
    if (e.key === "Enter") {
      document.getElementById("searchbtn").click();
      setSearchText("")
    }
  };

  const getcategory = async () => {
    try {
      let response = await axios.get(baseUrl + "Apicontroller/get_allcategory");
      // console.log("setCategory:", response.data);
      if (response.data.status === 200) {
        setCategory(response.data.data);
      }
    } catch {
      console.log("Issue in get category.");
    }
  };
  // open modal
  const openModal = () => {
    setIsModalOpen(true);
  }
  useEffect(() => getcategory(), []);
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await props.countCartLengthFunction();
        console.log("Length", data);
        setSPU(true);
        setPUM(props.popUpMessage);
        if (data !== undefined) {
          // alert(data.wishlist_count)
          setCL(data.data);
          setWL(data.wishlist_count)
        } else {
          setCL(0);
        }
        setAuthentication(props.authentication);
      } catch {
        console.log("Issue in getting header cart length.");
      }
    }
    fetchData();
  });
  const feedback = async (e) => {
    setIsModalOpen(false)
    e.preventDefault();
    const feedbackData = new FormData(e.target);
    let data = {};

    for (let [key, prop] of feedbackData) {
      data[key] = prop;
    }

    data = JSON.stringify(data, null, 2);
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/feedback",
        feedbackData
      );
      // if(response.status===200 || response.code===200) {
      //   document.querySelector("#registerError").innerHTML="User successfully registered & login";
      if (response.data.status === 201 || response.data.code === 201) {
        props.changePopupText(response.data.message);
      } else if (response.data.status === 200 || response.data.code === 200) {
        props.changePopupText("Feedback Successfully Submitted");

      }
    } catch {
      console.log("Issue in feedback");
    }
  };
  const openSideBar = () => {
    document.querySelector(".side_bar").style.transform = "translateX(0px)";
  };
  const closeSideBar = () => {
    document.querySelector(".side_bar").style.transform = "translateX(-900px)";
  };
  const closeMinor = () => {
    // alert("hi")
    document.querySelector(".sub_menu").style.visibility = "hidden !important";
  };
  const logoutFromSideBar = () => {
    closeSideBar();
    props.logoutUser();
  };

  {
    /*------------Close Category--------------------------------------- */
  }
  return (
    <div>
      {/* header start */}

      <header>
        <div className="container mw-100 pt-2 pb-3">
          <div className="text-center d-md-none">
          <h6 class="mb-3"><a href="tel:7627040986">Helpline Number - 7627040986</a></h6>
          </div>
          <div className="row">
            <div className="col-lg-3 col-4 side_link d-md-none d-flex align-items-center" onClick={() => openSideBar()}>
              {/* <div className> */}
              <img className="nav_bar_img" src="../img/navbar.png" />
              {/* <span>Menu</span> */}
              {/* </div> */}
            </div>
            {/* <div className="col-1"></div> */}
            <div className="col-md-3 col-4 brand_logo text-center">
              <Link to="/">
                <img src="../img/logo.png" />
              </Link>
            </div>
            <div className="col-4 side_link d-md-none d-block">
              
              <div className="d-flex text-center h-100 justify-content-between align-items-center">
                
                <Modal isOpen={isModalOpen} backdrop={true} style={{ top: "25%" }}>
                  <ModalHeader style={{ textAlign: "center" }}>
                    <b>Give Your Feedback</b>
                    <button className="btn" onClick={() => setIsModalOpen(false)}><span style={{ fontWeight: "600" }}>X</span></button>

                  </ModalHeader>
                  <form className="form" onSubmit={(e) => feedback(e)}>
                    <ModalBody>
                      <label>Full Name</label>
                      <input type="text" name="name" className="form-control" required></input>
                      <label>Contact</label>
                      <input type="text" name="contact" className="form-control" required maxLength={10} minLength={10}></input>
                      <label>Message</label>
                      <textarea id="message" name="message" className="form-control" required rows="4" cols="50"></textarea>
                    </ModalBody>
                    <ModalFooter>
                      <button className="btn" type="submit" style={{ background: '#2b2b2b', color: 'white' }} >Send Message</button>
                    </ModalFooter>
                  </form>
                </Modal>

                {authentication !== "" ? (
                  <>
                    <Link to={authentication === "" ? "/register" : "/profile"}>
                      <img src="../img/user.png" />
                    </Link>
                    <Link to="/basket">
                      <span className="cart-count">{cartLength}</span>
                      <img src="../img/cart.png" />
                    </Link>
                    <Link to="/wishlist">
                      <span className="cart-count">{wishLength}</span>
                      <img src="../img/heart.png" />
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/login">
                    <span className="cart-count">0</span>
                      <img src="../img/cart.png" />
                    </Link>
                    <Link to="/login">
                      <i className="fa fa-sign-in" style={{ fontSize: "1.4rem" }}></i>
                    </Link>
                    <Link to="/signup">
                      <i className="fa fa-user-plus" style={{ fontSize: "1.4rem" }}></i>
                    </Link>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6 mr-auto ml-auto mt-lg-auto mb-lg-auto mt-3 mt-lg-0">
              <div className="top_serch">
                <input
                  type="text"
                  name="search"
                  placeholder="Search for a product"
                  value={searchText}
                  onKeyPress={(e) => lookForSearch(e)}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Link
                  to={{
                    pathname: `/search-result/${searchText}`,
                  }}
                >
                  <img src="../img/search.png" alt="search" id="searchbtn" />
                </Link>
              </div>
            </div>
            {/* <div className="col-md-1"></div> */}
            <>
              <div className="col-md-3 side_link d-none d-md-block">
              <h6 class="mb-3"><a href="tel:7627040986">Helpline Number - 7627040986</a></h6>
                <div className="d-flex text-center justify-content-around align-items-center mt-2">
                  {authentication === "" ? (
                    <>
                      <Link to="/login">
                        <i className="fa fa-sign-in" style={{ fontSize: "1.4rem" }}></i>
                        <span className="icn_label">Sign In</span>
                      </Link>
                      <Link to="/signup">
                        <i className="fa fa-user-plus" style={{ fontSize: "1.4rem" }}></i>
                        <span className="icn_label">Register</span>
                      </Link>
                    </>
                  ) : (
                    <Link to="/profile">
                      <img src="../img/user.png" alt="" />
                      <div class="dropdown">
                        <span
                          class="dropdown-toggle icn_label"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {localStorage.getItem("supremetech_name")}
                        </span>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <Link class="dropdown-item icn_label" to="/profile">
                            My Account
                          </Link>
                          <Link class="dropdown-item icn_label" to="/myorders">
                            My Orders
                          </Link>
                          <span
                            class="dropdown-item icn_label"
                            onClick={() => props.logoutUser()}
                          >
                            Logout
                          </span>
                        </div>
                      </div>
                    </Link>
                  )}
                  <Link to="/basket">
                    <span className="cart-count">{cartLength}</span>
                    <img src="../img/cart.png" />
                    <span className="icn_label">Basket </span>
                  </Link>
                  {authentication !== "" ? (
                    <Link to="/wishlist">
                      <span className="cart-count">{wishLength}</span>

                      <img src="../img/heart.png" />
                      <span className="icn_label">Wishlist</span>
                    </Link>
                  ) : null}
                  <button className="btn " onClick={() => openModal()} style={{ fontSize: "12px",backgroundColor:'#2b2b2b',color:'white' }}>Feedback</button>

                </div>
               
              </div>
            </>
          </div>
          {popUpMessage !== "" ? (
            <Toast
              className="position-absolute popupboxInHeader"
              // style={{ top: "5rem", zIndex: 7, right: "10rem" }}
              show={showPopUp}
              onClose={toggleShowPopUp}
            >
              <Toast.Header style={{ background: "#d7314a", color: "white" }}>
                <span>{popUpMessage}</span>
              </Toast.Header>
            </Toast>
          ) : (
            ""
          )}
        </div>

        <hr className="mt-0 mb-0" />
        {/* mobile side bar start */}
        <section className="side_bar d-block d-lg-none d-md-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <span className="close_side d-flex d-lg-none d-md-none" onClick={() => closeSideBar()}>
                  <img src="../img/cut.png" className="m-auto" />
                </span>
              </div>
              <div className="col-md-12 mt-5 text-center w-100">
                <span className="d-flex d-lg-none d-md-none">
                  <img src="../img/logo.png" alt="" style={{ width: "14rem" }} />
                </span>
              </div>
              <div className="col-md-12 p-0 mt-5 pt-2">    
                {category
                  ? category.map((data, z) => {
                    return (
                      <div className="accordion" id="header_sidebar">
                        <div className="card">
                          <div className="card-header" id={"heading" + z}>
                            <h2 className="mb-0 d-flex w-100" style={{ justifyContent: "space-around" }}>
                              {/* <Link
                                  to={{
                                    pathname: `/category/${data.id}`,
                                  }}
                                  onClick={() => closeSideBar()}
                                > */}
                              <span style={{ fontSize: "1rem" }} className="d-block">
                                {data.name}
                              </span>
                              {/* </Link> */}
                              <button
                                className="btn btn-link w-50 ml-auto text-end"
                                type="button"
                                data-toggle="collapse"
                                data-target={"#collapse" + z}
                                aria-expanded="true"
                                aria-controls={"#collapse" + z}
                                style={{ textAlign: 'end' }}
                              >
                                <i className="fa fa-chevron-circle-down"></i>
                              </button>
                            </h2>
                          </div>
                          {data.sub_category.map((x, i) => {
                            return (
                              <div
                                id={"collapse" + z}
                                className="collapse"
                                aria-labelledby={"heading" + z}
                                data-parent="#header_sidebar"
                              >
                                <>
                                  <div className="card-body">
                                    <ul style={{ fontSize: "20px",paddingLeft:"0px" }}
                                      className="mt-0 mb-0">
                                      <li onClick={() => closeSideBar()}>
                                        {/* <Link
                                            to={{
                                              pathname: `/subcategory/${x.sub_id}`,
                                              state: {
                                                sub: x.sub_id,
                                              },
                                            }}
                                          > */}
                                        <h4 style={{ fontSize: "16px", color: '#dc3545' }}
                                        >{x.name}</h4>
                                        {/* </Link> */}
                                      </li>

                                      {data
                                        ? x.minor_category.map((elem) => {
                                          return (
                                            <li>
                                              <Link
                                                to={{
                                                  pathname: `/minorcategory/${elem.minor_id}`,
                                                  state: {
                                                    cat: data.id,
                                                    sub: x.sub_id,
                                                    minor: elem.minor_id,
                                                  },
                                                }}
                                              >
                                                <h4 style={{ fontSize: "15px", }}
                                                >  {elem.minor_name}</h4>
                                              </Link>
                                            </li>
                                          );
                                        })
                                        : null}
                                    </ul>
                                  </div>
                                </>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                  : ""}
              </div>
              {authentication !== "" && authentication !== null ? (
                <div className="text-center d-flex w-100">
                  <Link class="icn_label" to="/myorders" style={{
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}>
                    <button
                      style={{ background: "#dc3545", color: "white" }}
                      className="btn icn_label w-100"
                      onClick={() => closeSideBar()}
                    >
                      My Orders
                    </button>
                  </Link>
                  <button
                    className="btn icn_label"
                    style={{
                      background: "#dc3545", color: "white", width: "90%",
                      marginLeft: "5%",
                      marginRight: "5%",
                    }}
                    onClick={() => logoutFromSideBar()}
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <div className="text-center d-flex w-100">
                  <Link className="icn_label" to="/login" style={{
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}>
                    <button
                      style={{ background: "#dc3545", color: "white" }}
                      onClick={() => closeSideBar()}
                      className="btn icn_label w-100"
                    >
                      Login
                    </button>
                  </Link>
                  <Link className="icn_label" to="/signup" style={{
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}>
                    <button
                      style={{ background: "#dc3545", color: "white" }}
                      onClick={() => closeSideBar()}
                      className="btn icn_label w-100"
                    >
                      SignUp
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* mobile side bar end */}
        <div className="container-fluid p-0 top_menu w-100 m-0  d-none d-lg-block d-md-block">
          <div className="row  p-0 w-100 m-0 h-100">
            <div className="col-md-12 p-0 w-100 m-0 h-100">
              <ul className="first_ul mt-5 mt-lg-0">
              <Link to={{pathname: `/`}}>
                <h6 className="text-danger mt-2">Home</h6></Link>
                {category
                  ? category.map((data) => {
                    return (
                      <li id={data.product_id} key={data.product_id}>
                        <a className="text-danger">
                          {/* <Link
                              to={{
                                pathname: `/category/${data.id}`,
                              }}
                            > */}
                          {data.name}
                          {/* </Link> */}
                          <i
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          />
                        </a>
                        <div className="sub_menu">
                          <div className="container-fluid pb-3 pt-3">
                            <>
                              <div className="row">
                                {data
                                  ? data.sub_category.map((x) => {
                                    return (
                                      <div className="col-md-3">
                                        <ul>
                                          <li>
                                            <h4 style={{
                                              fontSize: "16px",
                                              color: '#dc3545'
                                            }}>
                                              {/* <Link
                                                    to={{
                                                      pathname: `/subcategory/${x.sub_id}`,
                                                      state: {
                                                        sub: x.sub_id,
                                                      },
                                                    }}
                                                  > */}
                                              {x.name}
                                              {/* </Link> */}
                                            </h4>
                                          </li>
                                        </ul>
                                        {data
                                          ? x.minor_category.map(
                                            (elem) => {
                                              return (
                                                <ul className="list-links">
                                                  <li  onClick={() => closeMinor()}>
                                                    <Link
                                                      to={{
                                                        pathname: `/minorcategory/${elem.minor_id}`,
                                                        state: {
                                                          cat: data.id,
                                                          sub: x.sub_id,
                                                          minor:
                                                            elem.minor_id,
                                                        },
                                                      }}
                                                    >
                                                      {elem.minor_name}
                                                    </Link>
                                                  </li>
                                                </ul>
                                              );
                                            }
                                          )
                                          : null}

                                      </div>
                                    );
                                  })
                                  : null}
                              </div>
                            </>
                          </div>
                        </div>
                      </li>
                    );
                  })
                  : null}
              </ul>
            </div>
          </div>
        </div>

        <hr className="mt-0 mb-0" />
      </header>
      {/* header end */}
    </div>
  );
}

export default Header;
