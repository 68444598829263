import React, { useState } from "react";
import { State } from "./State";
import axios from "axios";
import { baseUrl } from "./baseUrl";
import { Redirect } from "react-router";

function Login(props) {
  const loginUser = async (e) => {
    e.preventDefault();
    const loginData = new FormData(e.target);
    try {
      const response = await axios.post(
        baseUrl + "Users/login",
        loginData
      );
      console.log("login", response.data);
      if (response.data.code === 201) {
        document.querySelector("#loginError").innerHTML = response.data.message;
      } else if (response.data.code === 200) {
        document.querySelector("#loginError").innerHTML = "Check for otp!";
        const loginphone = document.querySelector("#loginphone").value;
        // const loginname=document.querySelector("#loginname").value;
        localStorage.setItem("supremetech_phone", loginphone);
        // localStorage.setItem("supremetech_name", loginname)
        props.history.push("/login-otp");
        // localStorage.setItem("supremetech_phone", response.data.email);
        // localStorage.setItem("supremetech_name", response.data.user_name);
        // props.changePopupText("User successfully login");
        // props.checkAndSetCredentials();
      }
    } catch {
      console.log("Issue in login");
    }
  };
  const [contact, setContact] = useState("")
  const validateSignupForm = (e) => {
      const reg = /^\d+$/;
      if (reg.test(e.target.value) || e.target.value === "") {
        setContact(e.target.value);
      }
  };
  if (
    localStorage.getItem("supremetech_authentication") === "" ||
    localStorage.getItem("supremetech_authentication") === null
  ) {
    return (
      <div>
        <section className="mt-5 mb-5 pt-5 pb-5 login_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 heading-div text-center mb-5">
                <h2 className="title">CUSTOMER LOGIN</h2>
              </div>
              {/* <div className="row"> */}
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <h6>Login</h6>
                  <hr />
                  <p id="loginError" className="font_monst gry_col mb-4">
                    Please sign in to continue
                  </p>
                  <form
                    id="loginform"
                    className="form"
                    onSubmit={(e) => loginUser(e)}
                  >
                    <input
                      type="text"
                      name="phone"
                      id="loginphone"
                      placeholder="Phone Number"
                      className="mb-4"
                      value={contact}
                      maxLength="10"
                      minLength="10"
                      required
                      onChange={(e)=>validateSignupForm(e)}
                    />
                    <div className="d-flex mt-3 justify-content-between">
                      <button
                        style={{ background: "#2b2b2b", color: "white" }}
                        type="submit"
                        className="btn knw_btn active"
                      >
                        Send OTP
                      </button>
                    </div>
                  </form>
                </div>
              {/* </div> */}
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
}

export default Login;
